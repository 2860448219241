import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { useFormik } from "formik";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { useNavigate } from "react-router-dom";

const AddPolicy = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      policy_title: "",
      user_type: [],
      policy_description: "",
    },
    validationSchema: Yup.object().shape({
      policy_title: Yup.string().required("Title is required"),
      user_type: Yup.array()
        .of(Yup.string().required("User type is required")) // Apply validation to each element in the array
        .required("At least one user type is required"), // Ensure the array itself is required
      policy_description: Yup.string().required(
        "Policy description is required"
      ),
    }),

    onSubmit: (values) => {
      const data = {
        policyTitle: values.policy_title,
        description: values.policy_description,
        // userType: values.user_type.map((data) => {
        //   return data === "HR" ? 1 : 2;
        // }),
      };
      ApiUtils.addPolicy(data).then((res) => {
        if (res.data.isSuccess) {
          navigate("/policy", { replace: true });
          Toaster("Policy Added", SUCCESS_TOASTIFY_TYPE);
          formik.setFieldValue("policyTitle", "");
          formik.setFieldValue("description", "");
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    },
  });
  return (
    <React.Fragment>
      <div>
        <div className="container flex items-center justify-between">
          <h1 className="text-2xl mb-4 font-bold">ADD POLICY</h1>
        </div>
        <hr className="mb-6   " />
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-6">
            <label
              htmlFor="countries"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Policy Title
            </label>
            <input
              id="policy_title"
              type="text"
              name="policy_title"
              className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Policy Title...."
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.errors.policy_title && formik.touched.policy_title ? (
              <div className="text-red-500 text-sm">
                {formik.errors.policy_title}
              </div>
            ) : null}
          </div>
          <div className="mb-6">
            <label
              htmlFor="countries"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Policy Description
            </label>
            <CKEditor
              config={{
                extraPlugins: [],
              }}
              editor={ClassicEditor}
              onReady={(editor) => {}}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                formik.setFieldValue("policy_description", data);
              }}
              data={formik.values.policy_description}
              style={{
                maxWidth: "400px",
                wordWrap: "break-word",
              }}
            />
            {formik.errors.policy_description &&
            formik.touched.policy_description ? (
              <div className="text-red-500 text-sm">
                {formik.errors.policy_description}
              </div>
            ) : null}
          </div>
          {/* 
          <div className="mb-6">
            <label
              htmlFor="countries"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Select User Type
            </label>
            <div className="flex items-center space-x-4">
              <label className="flex items-center">
                <input
                  id="user_type"
                  type="checkbox"
                  name="user_type"
                  value="HR"
                  defaultChecked={
                    formik.values.user_type === "HR" ? true : false
                  }
                  onChange={formik.handleChange}
                  className="form-checkbox h-5 w-5 text-blue-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">
                  HR
                </span>
              </label>

              <label className="flex items-center">
                <input
                  id="user_type"
                  type="checkbox"
                  name="user_type"
                  value="Employee"
                  defaultChecked={
                    formik.values.user_type === "Employee" ? true : false
                  }
                  onChange={formik.handleChange}
                  className="form-checkbox h-5 w-5 text-blue-500"
                />
                <span className="ml-2 text-gray-700 dark:text-gray-300">
                  Employee
                </span>
              </label>
            </div>
          </div> */}
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700"
            >
              ADD POLICY
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddPolicy;
