import React, { useEffect, useState } from "react";
import { GoLinkExternal } from "react-icons/go";
import TooltipItem from "../../helper/TooltipItem";
import { VscFeedback } from "react-icons/vsc";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import ApiUtils from "../../api/ApiUtils";
import { useCallback } from "react";
import { debounce } from "../../helper/Debounce";
import moment from "moment/moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { ONE, TEN } from "../../config/constants";

const Interviews = () => {
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [searchValue, setSearchValue] = useState("");
  const [candidateId, setCandidateId] = useState();
  const [interviewId, setInterviewId] = useState();
  const [interviewRound, setInterviewRound] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handelDownload = (data) => {
    window.open(data);
  };
  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
    setCandidateId("");
    setInterviewId("");
    setInterviewRound("");
    setIsEdit(false);
    formik.resetForm();
  };

  async function getAllCandidates() {
   
    let queryString = `Page=${currentPage}&PageSize=${TEN}&searchvalue=${searchValue}`;
      if(formatedStartDate==='Invalid date' || formatedEndDate==='Invalid date'){
        queryString += `&fromDate=&toDate=`;
      }else{
        queryString += `&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`;
      }

       

    ApiUtils.getCandidateForInterviewer(
      queryString
    ).then((res) => {
      if (res.data.isSuccess) {
        setList(res.data.data.listResponse ? res.data.data.listResponse : []);
        setTotalCount(res?.data?.data?.totalCount);
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      }
    });
  }





  const handleEdit = (data) => {
    setShowModal(true);
    setCandidateId(data.candidateId);
    setInterviewId(data.interviewId);
    setInterviewRound(data.interviewRound);
    setIsEdit(true);
  };

  // Handler for changing the current page
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    getAllCandidates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue, startDate, endDate]);
 
  // handler fro search
  const handleSearch = async (value) => {
    if (list.length > 0 && searchValue.length >= 3) {
      setSearchValue(value);
      await ApiUtils.getCandidateForInterviewer(
        `Page=${currentPage}&PageSize=${TEN}&searchvalue=${value}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
      )
        .then((res) => {
          setList(res.data.data.listResponse ? res.data.data.listResponse : []);
          setTotalCount(res?.data?.data?.totalCount);
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / 10)
              : []
          );
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      round: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      round: Yup.string()
        .oneOf([
          `Selected in ${interviewRound}`,
          `Rejected in ${interviewRound}`,
        ])
        .required(),
      reason: Yup.string().required("Feedback is required"),
    }),
    onSubmit: (values) => {
      const data = {
        interviewId: interviewId,
        interviewRound: interviewRound,
        candidateId: candidateId,
        roundStatus: values.round,
        roundFeadback: values.reason,
      };
      ApiUtils.addFeedback(data).then((res) => {
        if (res.data.isSuccess) {
          Toaster("Feedback Submitted", SUCCESS_TOASTIFY_TYPE);
          formik.resetForm();
          setShowModal(false);
          setCandidateId("");
          setInterviewId("");
          setInterviewRound("");
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [list]);

  const handleJoin = (data) => {
    window.open(data);
  };
  const handleRadioChange = (event) => {
    formik.setFieldValue("round", event.target.value);
  };

  const handleFeedback = (data) => {
    setCandidateId(data.candidateId);
    setInterviewId(data.interviewId);
    setInterviewRound(data.interviewRound);
    setShowModal(true);
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">INTERVIEWS</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Candidate Name
              </th>
              <th scope="col" className="px-6 py-3">
                Vacancy
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Time
              </th>
              <th scope="col" className="px-6 py-3">
                Resume
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">{data.candidateName}</td>
                    <td className="px-6 py-4">{data.vacancy}</td>
                    <td className="px-6 py-4">
                      {moment(data.interviewDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">
                      {moment(data.interviewDate).format("hh:mm A")}
                    </td>
                    <td className="px-6 py-4">
                      <RiDownloadCloud2Line
                        className="text-2xl cursor-pointer hover:text-blue-500"
                        onClick={() => handelDownload(data.resumeLink)}
                      />
                    </td>
                    <td className="px-6 py-4 gap-5">
                      <div className="flex items-center space-x-2 gap-4">
                        <TooltipItem
                          className="text-blue-500 text-lg"
                          tooltipsText="Join"
                          onClick={() => handleJoin(data.meetLink)}
                        >
                          <GoLinkExternal />
                        </TooltipItem>
                        {data.currentStatus !== "ShortList" ? (
                          <TooltipItem
                            onClick={(e) => handleEdit(data)}
                            className="text-lg text-green-500"
                            tooltipsText="Edit"
                          >
                            <FaEdit />
                          </TooltipItem>
                        ) : (
                          <TooltipItem
                            className="text-lg text-green-500"
                            tooltipsText="Add Feedback"
                            onClick={() => handleFeedback(data)}
                          >
                            <VscFeedback />
                          </TooltipItem>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl  overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5 ">{`${
              isEdit ? "Update Feedback" : "Add Feedback"
            }`}</h2>

            <form>
              <div className="flex gap-5">
                <ul className="grid w-full gap-6 md:grid-cols-2 mb-6">
                  <li>
                    <input
                      type="radio"
                      id="male"
                      value={`Selected in ${interviewRound}`}
                      name="round"
                      className="hidden peer"
                      onChange={handleRadioChange}
                      checked={
                        formik.values.round === `Selected in ${interviewRound}`
                      }
                      required
                    />
                    <label
                      htmlFor="male"
                      className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-green-500 peer-checked:border-green-600 peer-checked:text-green-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                    >
                      <div className="block">
                        <div className="w-full text-lg font-semibold flex">
                          Selected in {interviewRound}
                        </div>
                      </div>
                    </label>
                  </li>
                  <li>
                    <input
                      type="radio"
                      id="female"
                      name="round"
                      value={`Rejected in ${interviewRound}`}
                      className="hidden peer"
                      onChange={handleRadioChange}
                      checked={
                        formik.values.round === `Rejected in ${interviewRound}`
                      }
                      required
                    />
                    <label
                      htmlFor="female"
                      className="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-red-500 peer-checked:border-red-600 peer-checked:text-red-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                    >
                      <div className="block">
                        <div className="w-full text-lg font-semibold flex">
                          Rejected in {interviewRound}
                        </div>
                      </div>
                    </label>
                  </li>
                </ul>
                {formik.errors.round && formik.touched.round ? (
                  <div className="text-red-500 text-sm mb-0">
                    {formik.errors.round}
                  </div>
                ) : null}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="reason"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Add Feedback
                </label>
                <textarea
                  id="reason"
                  className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Add Feedback..."
                  name="reason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                />
                {formik.errors.reason && formik.touched.reason ? (
                  <div className="text-red-500 text-sm mb-0">
                    {formik.errors.reason}
                  </div>
                ) : null}
              </div>
            </form>
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={formik.handleSubmit}
                className="px-4 py-2 bg-green-400 text-white rounded"
              >
                {`${isEdit ? "Update" : "Submit"}`}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Interviews;
