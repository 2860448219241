import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ConfirmationPage = () => {
  const { token } = useParams();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const response = await fetch(`/apiAccount/ConfirmEmail?token=${token}`);
        const data = await response.json();
        if (data.isSuccess) {
          setConfirmationMessage("Confirmed Email Successfully!");
          navigate("/login");
        } else {
          setConfirmationMessage("Something is wrong with the link.");
        }
      } catch (error) {
        console.error("Error confirming email:", error);
        setConfirmationMessage(
          "Error confirming email. Please try again later."
        );
      }
    };

    if (token) {
      confirmEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div>
      <p>{confirmationMessage}</p>
    </div>
  );
};

export default ConfirmationPage;
