import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import React from "react";
import { useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { useEffect } from "react";
import moment from "moment";
import { ONE } from "../../config/constants";

const Calender = () => {
  const [dateList, setDateList] = useState([]);

  async function getEvents() {
    ApiUtils.getFestivalLeaves(`?Page=${ONE}&PageSize=50`)
      .then((res) => {
        if (res.data.isSuccess) {
          setDateList(res.data.data.listResponse);
        }
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    getEvents();
  }, []);

  const INITIAL_EVENTS = dateList?.map((data) => {
    const festivalDate = moment(data.festivalDate).format("YYYY-MM-DD");
    const list = {
      id: data.festivalLeaveId,
      title: data.festivalName,
      start: festivalDate,
      color: data.isFloatingLeave === false ? "#5a49f8" : "green",
    };
    return list;
  });

  return (
    <React.Fragment>
      <div className="demo-app">
        <div className="demo-app-main">
          <div className="ymb-3 text-lg">
            <ul className="flex gap-5">
              <li>
                <span className="dot blue"></span> Fixed Leave
              </li>
              <li>
                <span className="dot green"></span> Optional Leave
              </li>
            </ul>
          </div>

          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "",
              right: "title",
            }}
            initialView="dayGridMonth"
            events={INITIAL_EVENTS}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Calender;
