import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeUserAuthDetails } from "../../redux/slices/authSlice";
import getSidebarItems from "../../helper/SidebarHelper";
import { useSidebar } from "../../helper/SidebarContext";
import { FiMoreVertical } from "react-icons/fi";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const handleLogoutClick = () => {
    setIsLogoutOpen(!isLogoutOpen); // Toggle the state on clicking the more icon
  };

  const searchParams = useLocation();

  const userRole = useSelector(
    (state) => state?.authSlice?.userDetails?.userRole
  );

  const userName = useSelector(
    (state) => state?.authSlice?.userDetails?.userName
  );
  const sidebarItems = getSidebarItems(userRole);

  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(removeUserAuthDetails());
    navigate("/");
  };
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const [activeItem, setActiveItem] = useState(searchParams.pathname);
  const handleMenuItemClick = (path) => {
    toggleSidebar();
    setActiveItem(path);
  };

  return (
    <aside
      id="logo-sidebar"
      className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
        isSidebarOpen ? "translate-x-0" : "-translate-x-full"
      } -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`}
      aria-label="Sidebar"
    >
      <div className="h-full px-3 flex flex-col border-r pb-4 overflow-y-auto bg-white dark:bg-gray-800">
        <ul className="space-y-2 font-medium">
          {sidebarItems?.map((items, index) => {
            return (
              <li
                key={index}
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white group ${
                  items.path === activeItem
                    ? "bg-gray-700 text-white"
                    : "hover:bg-gray-100 dark:hover:bg-gray-700"
                }`}
              >
                {items.icon}
                <Link
                  to={items.path}
                  onClick={() => handleMenuItemClick(items.path)}
                  className="ml-3"
                >
                  {items.title}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="mt-auto">
          {isLogoutOpen && (
            <li
              onClick={handleLogout}
              className="flex items-center cursor-pointer p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <FiLogOut className="text-red-500" />
              <button className="text-red-500 ml-3">Logout</button>
            </li>
          )}
          <div className="flex p-3 cursor-pointer" onClick={handleLogoutClick}>
            <img
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                userName
              )}&background=c7d2fe&color=3730a3&bold=true`}
              alt=""
              className="w-10 h-10 rounded-md"
            />
            <div className="flex justify-between items-center overflow-hidden transition-all  w-52 ml-3">
              <div className="leading-4">
                <h4 className="font-semibold">{userName}</h4>
                <span className="text-xs text-gray-600">{userRole}</span>
              </div>
              <FiMoreVertical size={20} />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
