import React, { useEffect, useState } from "react";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { ONE } from "../../config/constants";
const RecentLeave = () => {
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  useEffect(() => {
    setTotalCount(2);
    setTotalRecords(1);
  }, []);

  const isActive = true;
  return (
    <>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Employee Name
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                start date to end date
              </th>
              <th scope="col" className="px-6 py-3">
                Reason
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">25-04-2023 to 26-04-2023</td>
              <td className="px-6 py-4">sdkjnskfskfs</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Approved" : "Rejected"}
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">25-04-2023 to 26-04-2023</td>
              <td className="px-6 py-4">sdkjnskfskfs</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Approved" : "Rejected"}
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">25-04-2023 to 26-04-2023</td>
              <td className="px-6 py-4">sdkjnskfskfs</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Approved" : "Rejected"}
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">25-04-2023 to 26-04-2023</td>
              <td className="px-6 py-4">sdkjnskfskfs</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Approved" : "Rejected"}
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">25-04-2023 to 26-04-2023</td>
              <td className="px-6 py-4">sdkjnskfskfs</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Approved" : "Rejected"}
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">25-04-2023 to 26-04-2023</td>
              <td className="px-6 py-4">sdkjnskfskfs</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Approved" : "Rejected"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default RecentLeave;
