import React, { useEffect } from "react";
import { useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { useSelector } from "react-redux";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import DatePicker from "react-datepicker";
import { useFormik } from "formik";
import * as Yup from "yup";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import moment from "moment/moment";
import { ONE, TEN } from "../../config/constants";

const EarlyGoing = () => {
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(ONE);
  const [totalCount, setTotalCount] = useState();
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [editDataId, setEditDataId] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const formik = useFormik({
    initialValues: {
      leave_type: 1,
      reason: "",
      date: "",
      hours: "",
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Reason is Required"),
      date: Yup.date().required("date is required"),
      hours: Yup.string().required("hours required"),
    }),
    onSubmit: (values) => {
      const data = {
        goingComingDate: values.date,
        earlyLateType: Number(values.leave_type),
        totalHours: values.hours.toString(),
        reason: values.reason,
      };
      if (isEdit) {
        data.earlyGoingLateComingId = editDataId;
      }
      ApiUtils.addGoingCoiming(data)
        .then((res) => {
          if (res.data.isSuccess) {
            formik.setValues({
              leave_type: 1,
              reason: "",
              date: "",
              hours: "",
            });
            resetForm();
            setShowModal(false);
            Toaster("Leave Apply Success", SUCCESS_TOASTIFY_TYPE);
            getTableList();
          } else {
            Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
          }
        })
        .catch((err) => {
          Toaster(err, ERROR_TOASTIFY_TYPE);
        });
    },
  });

  const resetForm = () => {
    formik.resetForm();
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const id = useSelector((state) => state?.authSlice?.userDetails);

  const toggleModal = () => {
    setShowModal(true);
    setIsEdit(false);
  };

  const handleEdit = (data) => {
    formik.setValues({
      leave_type: data?.earlyLateType,
      reason: data?.reason,
      date: moment(data?.goingComingDate).format("YYYY-MM-DD"),
      hours: data?.totalHours,
    });
    setIsEdit(true);
    setEditDataId(data.earlyGoingLateComingId);
    setShowModal(true);
  };

  async function getTableList() {
    ApiUtils.getEarlyGoingList(
      `PageSize=${TEN}&userId=${id.userId}&Page=${currentPage}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    )
      .then((res) => {
        setList(res.data.data.listResponse ? res.data.data.listResponse : []);
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startDate, endDate]);

  const closeModal = () => {
    setShowModal(false);
    resetForm();
    setIsEdit(false);
    formik.setValues({
      leave_type: 1,
      reason: "",
      date: "",
      hours: "",
    });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">EARLY GOING / LATE COMING</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            // onChange={handleSearch}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className="text-white flex items-center gap-1 bg-green-600 p-5 hover:bg-green-900 h-8 rounded-full font-bold"
            onClick={toggleModal}
          >
            <span className="text-center">Apply</span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Early going / Late coming
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Reason
              </th>
              <th scope="col" className="px-6 py-3">
                Total hours
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {data.earlyLateType === 1 ? "Early Going" : "Late Coming"}
                    </td>
                    <td className="px-6 py-4">
                      {moment(data.goingComingDate).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-6 py-4">{data.reason}</td>
                    <td className="px-6 py-4">{data.totalHours} hours</td>
                    <td className="px-6 py-4">
                      {new Date(data.goingComingDate).toLocaleString() >=
                      new Date().toLocaleString() ? (
                        <TooltipItem
                          onClick={() => handleEdit(data)}
                          className="text-lg text-green-500"
                          tooltipsText="Edit"
                        >
                          <FaEdit />
                        </TooltipItem>
                      ) : (
                        <span className="text-red-500">Not Allowed</span>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center justify-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl  overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5 ">{`${
              isEdit ? "Update Leave" : "Apply For Leave"
            }`}</h2>

            <form onSubmit={formik.handleSubmit}>
              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Select Leave Type
                </label>
                <div className="flex items-center">
                  <label className="mr-4">
                    <input
                      type="radio"
                      name="leave_type"
                      value={1}
                      defaultChecked={
                        formik.values.leave_type === 1 ? true : false
                      }
                      className="form-radio"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    <span className="ml-2">Early Going</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="leave_type"
                      value={2}
                      className="form-radio"
                      defaultChecked={
                        formik.values.leave_type === 2 ? true : false
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span className="ml-2">Late Coming</span>
                  </label>
                </div>
                {formik.errors.leave_type && formik.touched.leave_type ? (
                  <div className="text-red-500 text-sm mb-0">
                    {formik.errors.leave_type}
                  </div>
                ) : null}
              </div>
              <div className="flex justify-between gap-9">
                <div className="w-full mb-6">
                  <label
                    htmlFor="reason"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Reason..."
                    name="date"
                    defaultValue={moment(formik.values.date).format(
                      "YYYY-MM-DD"
                    )}
                    min={new Date().toISOString().split("T")[0]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.date && formik.touched.date ? (
                    <div className="text-red-500 text-sm mb-0">
                      {formik.errors.date}
                    </div>
                  ) : null}
                </div>
                <div className="w-full mb-6">
                  <label
                    htmlFor="reason"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter Hours
                  </label>
                  <input
                    type="number"
                    id="number"
                    className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="hours"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.hours}
                  />
                  {formik.errors.hours && formik.touched.hours ? (
                    <div className="text-red-500 text-sm mb-0">
                      {formik.errors.hours}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="reason"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Reason for Leave
                </label>
                <textarea
                  id="reason"
                  className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Reason..."
                  name="reason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.reason}
                />
                {formik.errors.reason && formik.touched.reason ? (
                  <div className="text-red-500 text-sm mb-0">
                    {formik.errors.reason}
                  </div>
                ) : null}
              </div>

              <div className="flex justify-end">
                <button
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  {`${isEdit ? "Update" : "Apply"}`}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EarlyGoing;
