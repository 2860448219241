const BASE_URL = process.env.REACT_APP_BASE_URL;
const HR = "HR";
const ADMIN = "Admin";
const MANAGER = "Manager";
const EMPLOYEE = "Employee";
const ONE = 1;
const TWO = 2;
const TEN = 10;
const FIFTY = 50;
const SET_TIME_OUT_400 = 400;

module.exports = {
  BASE_URL,
  HR,
  ADMIN,
  MANAGER,
  EMPLOYEE,
  ONE,
  TWO,
  TEN,
  SET_TIME_OUT_400,
  FIFTY,
};
