import React, { useState } from "react";
import Profile from "./Profile";
import Education from "./Education";
import { FaUser, FaGraduationCap, FaClipboardList, FaWallet } from "react-icons/fa";
import PersonalDetail from "./PersonalDetail";
import { AiOutlineBank, AiOutlineProfile } from "react-icons/ai";
import BankDetails from "./BankDetails";
import LeaveReport from "./LeaveReport";
import { useParams } from "react-router-dom";
import PaySlip from "./PaySlip";

const Tabs = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="flex flex-row">
      <div className="flex flex-col w-1/6 mt-2 h-[78vh]">
        <ul className="text-gray-500 bg-white rounded-sm w-full p-2 mt-2 h-[100vh]">
          <li>
            <div
              className={`py-2 pl-4 cursor-pointer mb-1 hover:bg-gray-100 rounded-full ${
                activeTab === 1
                  ? "text-white bg-gray-700 hover:bg-gray-700 rounded-full"
                  : "text-indigo-900 "
              }`}
              onClick={() => handleTabClick(1)}
            >
              <FaUser className="inline mr-2" /> Profile
            </div>
          </li>
          <li>
            <div
              className={`py-2 pl-4 cursor-pointer mb-1 hover:bg-gray-100 rounded-full ${
                activeTab === 2
                  ? "text-white bg-gray-700 hover:bg-gray-700 rounded-full"
                  : "text-indigo-900 "
              }`}
              onClick={() => handleTabClick(2)}
            >
              <FaGraduationCap className="inline mr-2" />
              Education Details
            </div>
          </li>
          <li>
            <div
              className={`py-2 pl-4 cursor-pointer mb-1 hover:bg-gray-100 rounded-full ${
                activeTab === 3
                  ? "text-white bg-gray-700 hover:bg-gray-700 rounded-full"
                  : "text-indigo-900 "
              }`}
              onClick={() => handleTabClick(3)}
            >
              <FaClipboardList className="inline mr-2" /> Leave Report
            </div>
          </li>
          <li>
            <div
              className={`py-2 pl-4 cursor-pointer mb-1 hover:bg-gray-100 rounded-full ${
                activeTab === 4
                  ? "text-white bg-gray-700 hover:bg-gray-700 rounded-full"
                  : "text-indigo-900 "
              }`}
              onClick={() => handleTabClick(4)}
            >
              <AiOutlineProfile className="inline mr-2" /> Personal Details
            </div>
          </li>
          <li>
            <div
              className={`py-2 pl-4 cursor-pointer mb-1 hover:bg-gray-100 rounded-full ${
                activeTab === 5
                  ? "text-white bg-gray-700 hover:bg-gray-700 rounded-full"
                  : "text-indigo-900 "
              }`}
              onClick={() => handleTabClick(5)}
            >
              <AiOutlineBank className="inline mr-2" /> Bank Details
            </div>
          </li>
          <li>
            <div
              className={`py-2 pl-4 cursor-pointer mb-1 hover:bg-gray-100 rounded-full ${
                activeTab === 6
                  ? "text-white bg-gray-700 hover:bg-gray-700 rounded-full"
                  : "text-indigo-900 "
              }`}
              onClick={() => handleTabClick(6)}
            >
              <FaWallet  className="inline mr-2" /> Pay Slip
            </div>
          </li>
        </ul>
      </div>

      <div className="flex-grow p-4">
        {activeTab === 1 && (
          <div>
            <Profile id={id} />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <Education id={id} />
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <LeaveReport userId={id} />
          </div>
        )}
        {activeTab === 4 && (
          <div>
            <PersonalDetail id={id} />
          </div>
        )}
        {activeTab === 5 && (
          <div>
            <BankDetails id={id} />
          </div>
        )}
        {activeTab === 6 && (
          <div>
            <PaySlip id={id} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
