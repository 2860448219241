import React, { useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { useDispatch } from "react-redux";
import { setUserAuthDetails } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE } from "../../helper/enum";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    forget: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });

    // Clear error for the field when its value changes
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform validation
    const errors = {};

    if (!loginData.email) {
      errors.email = "Email is required";
    }

    if (!loginData.password) {
      errors.password = "Password is required";
    }

    // If there are errors, set them in the state and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    const body = {
      userName: loginData.email,
      password: loginData.password,
    };
    try {
      const res = await ApiUtils.authLogin(body);
      let formattedRes = {
        userName: res.data.userName,
        userRole: res.data.roles,
        userToken: res.data.token,
        userId: res.data.userId,
        status: res.data.status
      };
      if (res.status === 200) {
        await localStorage.setItem("user", JSON.stringify(formattedRes));
        await dispatch(setUserAuthDetails(formattedRes));
        await navigate("/dashboard");
      }
    } catch (err) {
      Toaster(err.data, ERROR_TOASTIFY_TYPE);
    }
  };

  const handleForgotPassword = () => {
    // Implement the logic to handle forgot password click
    setShowModal(true);
  };

  const onResetPassword = () => {
    const errors = {};

    if (!loginData.forget) {
      errors.forget = "Email is required";
    }

    // If there are errors, set them in the state and return
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    ApiUtils.forgotPassword({ email: loginData?.forget })
      .then((res) => {
        if (res.status === 200) {
          setEmailSent(true);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleOk = () => {
    setShowModal(false);
    setEmailSent(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <section className="bg-gray-200 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="*"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            className="w-full h-16 mr-2"
            src="https://www.tecmantras.com/imgs/logo.png"
            alt="logo"
          />
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Login
            </h1>
            <form className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    errors.email ? "dark:border-red-500" : ""
                  }`}
                  placeholder="name@example.com"
                  required
                />
                {errors.email && (
                  <span className="text-red-500 text-sm">{errors.email}</span>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      errors.password ? "dark:border-red-500" : ""
                    }`}
                    placeholder="********"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 px-3 py-2 bg-gray-200 rounded-md border border-gray-300 hover:bg-gray-300 focus:outline-none"
                    id="toggleButton"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.password && (
                  <span className="text-red-500 text-sm">
                    {errors.password}
                  </span>
                )}
              </div>

              <div className="text-white text-end">
                <button
                  className="text-sm text-blue-700 hover:underline dark:text-blue-500"
                  type="button"
                  onClick={handleForgotPassword}
                >
                  Forgot Password ?
                </button>
              </div>

              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={handleSubmit}
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md">
            {emailSent ? (
              <>
                <div
                  className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
                  role="alert"
                >
                  <p className="font-bold">Email Sent Successfully</p>
                  <p className="text-sm">
                    Reset Password link has been sent to your email
                  </p>
                </div>
                <div className="flex justify-end mt-5">
                  <button
                    className="ml-4 px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700"
                    onClick={handleOk}
                  >
                    OK
                  </button>
                </div>
              </>
            ) : (
              <>
                <h2 className="text-2xl font-semibold mb-4">
                  Forgot Password?
                </h2>
                <p className="mb-4">
                  Enter your email address below to reset your password.
                </p>
                <input
                  type="email"
                  className={`border border-gray-300 px-4 py-2 rounded-md w-full mb-4 ${
                    errors.forget ? "border-red-500" : ""
                  }`}
                  placeholder="Enter your email"
                  name="forget"
                  onChange={handleChange}
                />
                {errors.forget && (
                  <span className="text-red-500 text-sm">{errors.forget}</span>
                )}
                <div className="flex justify-end">
                  <button
                    className="text-blue-600 font-medium hover:underline"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="ml-4 px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700"
                    onClick={onResetPassword}
                  >
                    Reset Password
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Login;
