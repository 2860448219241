import React, { useEffect } from "react";
import { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import {
  AiFillDelete,
  AiOutlineMinusCircle,
  AiOutlinePlus,
} from "react-icons/ai";
import { FaEdit, FaEye } from "react-icons/fa";
import TooltipItem from "../../helper/TooltipItem";
import DatePicker from "react-datepicker";
import { useCallback } from "react";
import { debounce } from "../../helper/Debounce";
import moment from "moment";
import ApiUtils from "../../api/ApiUtils";
import { useFormik } from "formik";
import Toaster from "../../helper/Toaster";
import * as Yup from "yup";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import Swal from "sweetalert2";

const Expenses = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [totalRecords, setTotalRecords] = useState(1);
  const [list, setList] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleAddClick = () => {
    formik.setFieldValue("items", [
      ...formik.values.items,
      { itemName: "", price: "", quantity: "", total: 0 },
    ]);
  };

  const handleRemoveClick = (index) => {
    const newItems = [...formik.values.items];
    newItems.splice(index, 1);
    formik.setFieldValue("items", newItems);
  };

  function calculateTotal(index) {
    const price = parseFloat(formik.values.items[index]?.price) || 0;
    const quantity = parseFloat(formik.values.items[index]?.quantity) || 0;
    const total = (price * quantity).toFixed(0);
    if (formik.values.items[index]?.total !== total) {
      formik.setFieldValue(`items.${index}.total`, total);
    }
    return total;
  }

  function grandTotal() {
    let billTotal = 0;
    formik.values.items?.forEach((item, index) => {
      const itemTotal = parseFloat(item?.total) || 0;
      billTotal += itemTotal;
    });
    if (billTotal !== formik.values.total) {
      formik.setFieldValue("total", billTotal);
    }
    return billTotal;
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const image = {
      file,
      preview: URL.createObjectURL(file),
    };

    setSelectedImage(image);

    // Update formik values with the selected image
    formik.setFieldValue("bill_photo", file);
  };

  const removeImage = () => {
    setSelectedImage(null);
    formik.setFieldValue("bill_photo", null);
  };
  const validationSchema = Yup.object().shape({
    bill_date: Yup.date().required("Bill Date is required"),
    bill_number: Yup.string().required("Bill Number is required"),
    items: Yup.array().of(
      Yup.object().shape({
        itemName: Yup.string().required("Item Name is required"),
        price: Yup.number()
          .required("Price is required")
          .positive("Price must be positive"),
        quantity: Yup.number()
          .required("Quantity is required")
          .integer("Quantity must be an integer")
          .positive("Total must be positive"),
        total: Yup.number().required("Total is required"),
      })
    ),
    purpose: Yup.string().required("Purpose is required"),
    bill_photo: Yup.mixed().required("Photo is Required"),
  });

  const formik = useFormik({
    initialValues: {
      bill_date: "",
      bill_number: "",
      items: [{ itemName: "", price: "", quantity: "", total: "" }],
      total: 0,
      purpose: "",
      bill_photo: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("BillNumber", values.bill_number);
      formData.append("TotalAmount", values.total);
      formData.append("Purpose", values.purpose);
      formData.append("BillDate", values.bill_date);
      formData.append("BillImage", values.bill_photo);

      // Define the itemsArray based on the values
      const itemsArray = values.items.map((data) => ({
        itemName: data.itemName,
        price: data.price,
        quantity: data.quantity,
        itemTotal: data.total,
      }));

      if (isEdit) {
        // If it's an update operation, append the BillId and updateItemsArray
        formData.append("BillId", editId);
        const updateItemsArray = values.items?.map((data) => ({
          itemId: data.id,
          itemName: data.itemName,
          price: data.price,
          quantity: data.quantity,
          itemTotal: data.total,
        }));
        formData.append("Item", JSON.stringify(updateItemsArray) || [1]);
      } else {
        // If it's not an update operation, append the itemsArray
        formData.append("Item", JSON.stringify(itemsArray) || [1]);
      }

      if (isEdit) {
        // Update API call
        ApiUtils.updateExpense(formData).then((res) => {
          if (res.data.isSuccess) {
            Toaster("Expense successfully updated", SUCCESS_TOASTIFY_TYPE);
            handleClosePopup();
            getListofExpenses();
            setSelectedImage(null);
          } else {
            Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
          }
        });
      } else {
        // Add API call
        ApiUtils.addExpense(formData).then((res) => {
          if (res.data.isSuccess) {
            Toaster("Expense successfully added", SUCCESS_TOASTIFY_TYPE);
            handleClosePopup();
            getListofExpenses();
            setSelectedImage(null);
          } else {
            Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
          }
        });
      }
    },
  });

  const getListofExpenses = async () => {
    await ApiUtils.getAllExpense(
      `Page=${currentPage}&PageSize=10&SearchValue=${searchValue}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    ).then((res) => {
      if (res.data.isSuccess) {
        setList(
          res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
        );
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / 10)
            : []
        );
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  useEffect(() => {
    getListofExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleView = (data) => {
    window.open(data.billImage);
  };

  const deleteExpense = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiUtils.deleteExpense(`billId=${data}`)
          .then((res) => {
            if (res.data.isSuccess) {
              Swal.fire({
                title: "Deleted!",
                text: "Expense has been deleted.",
                icon: "success",
              });

              getListofExpenses();
            } else {
              Swal.fire({
                title: "Error!",
                text: res.data.message,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            // Handle API call error
            Swal.fire({
              title: "Error!",
              text: "An error occurred while deleting the expense.",
              icon: "error",
            });
          });
      }
    });
  };

  const toggleModal = () => {
    setShowPopup((previous) => !previous);
    setIsEdit(false);
  };

  //to get the data of selected bill
  const getItemsbyId = (id) => {
    ApiUtils.itemsById(id).then(async (res) => {
      if (res.data.isSuccess) {
        setEditId(res.data.data.getExpensesViewModel.billId);
        formik.setValues({
          bill_date:
            moment(res.data.data?.getExpensesViewModel.billDate).format(
              "YYYY-MM-DD"
            ) || "",
          bill_number: res.data.data?.getExpensesViewModel.billNumber || "",
          items: res.data.data?.getItems?.map((data, index) => ({
            id: data.itemId,
            price: data.price,
            itemName: data.itemName,
            total: data.itemTotal,
            quantity: data.quantity,
          })),
          total: res.data.data?.getExpensesViewModel.total || 0,
          purpose: res.data.data?.getExpensesViewModel.purpose || "",
          bill_photo: res.data.data?.getExpensesViewModel.billImage || null,
        });
        await setShowPopup(true);
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  const handleEditExpense = (data) => {
    getItemsbyId(data.billId);
    setIsEdit(true);
  };

  const handleSearch = async (value) => {
    setSearchValue(value);
    if (list.length > 0 && value.length >= 3) {
      ApiUtils.getAllExpense(
        `Page=${currentPage}&PageSize=10&SearchValue=${value}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
      ).then((res) => {
        if (res.data.isSuccess) {
          setList(
            res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
          );
          setTotalCount(
            res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
          );
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / 10)
              : []
          );
        }
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [list]);

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedImage([]);
    formik.resetForm();
    setEditId("");
    setIsEdit(false);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">EXPENSES</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className="text-white flex items-center gap-1 bg-green-600 p-5 hover:bg-green-900 h-8 rounded-full font-bold"
            onClick={toggleModal}
          >
            <AiOutlinePlus />
            <span className="text-center">ADD </span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto justify-between shadow-md sm:rounded-lg">
        <table className="w-full justify-between text-sm text-left text-gray-500 ">
          <thead className="text-xs justify-between uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Purpose
              </th>
              <th scope="col" className="px-6 py-3">
                Bill Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Total items
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((data) => {
                return (
                  <tr
                    key={data.billId}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {moment(data.billDate).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-6 py-4">{data.purpose}</td>
                    <td className="px-6 py-4">₹ {data.totalAmount}</td>
                    <td className="px-6 py-4">{data.quantity}</td>
                    <td className="px-6 py-4 gap-5">
                      <div className="flex items-center gap-4">
                        <TooltipItem
                          onClick={() => handleView(data)}
                          className="text-blue-500 text-lg"
                          tooltipsText="View Bill"
                        >
                          <FaEye />
                        </TooltipItem>
                        <TooltipItem
                          onClick={() => handleEditExpense(data)}
                          className="text-lg text-green-500"
                          tooltipsText="Edit"
                        >
                          <FaEdit />
                        </TooltipItem>
                        <TooltipItem
                          onClick={() => deleteExpense(data.billId)}
                          className="text-lg text-red-500"
                          tooltipsText="Delete"
                        >
                          <AiFillDelete />
                        </TooltipItem>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center justify-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center mt-20 ml-24 justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl h-[80vh] overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">
              {isEdit ? "Edit Expense" : "Add Expense"}
            </h2>
            <form>
              <div className="flex gap-5 mb-4">
                <div className="col-span-2 flex-grow">
                  <label
                    htmlFor="countries"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Bill Date
                  </label>
                  <div date-rangepicker="" className="mb-4 items-center">
                    <input
                      name="bill_date"
                      type="date"
                      className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Select date start"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      defaultValue={formik.values.bill_date}
                    />
                    {formik.errors.bill_date && formik?.touched.bill_date ? (
                      <div className="text-red-500 text-sm mb-0">
                        {formik.errors.bill_date}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-span-2 flex-grow">
                  <label
                    htmlFor="reason"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Bill Number
                  </label>
                  <input
                    type="number"
                    id="reason"
                    className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter Bill Number..."
                    name="bill_number"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    defaultValue={formik.values.bill_number}
                  />
                  {formik.errors.bill_number && formik?.touched.bill_number ? (
                    <div className="text-red-500 text-sm mb-0">
                      {formik.errors.bill_number}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                {formik.values.items?.map((item, index) => (
                  <div key={index} className="flex mb-6 justify-between">
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`items.${index}.itemName`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Item Name
                      </label>
                      <input
                        type="text"
                        id={`items.${index}.itemName`}
                        name={`items.${index}.itemName`}
                        defaultValue={formik.values.items[index]?.itemName}
                        onChange={formik.handleChange}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Item Name"
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.items &&
                        formik.errors.items &&
                        formik.touched.items[index]?.itemName &&
                        formik.errors.items[index]?.itemName && (
                          <div className="text-red-500 text-sm mb-0">
                            {formik.errors.items[index]?.itemName}
                          </div>
                        )}
                    </div>
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`items.${index}.price`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Price
                      </label>
                      <input
                        type="number"
                        min={0}
                        id={`items.${index}.price`}
                        name={`items.${index}.price`}
                        defaultValue={parseFloat(
                          formik.values.items[index]?.price
                        )}
                        onChange={formik.handleChange}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Price"
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.items &&
                        formik.errors.items &&
                        formik.touched.items[index]?.price &&
                        formik.errors.items[index]?.price && (
                          <div className="text-red-500 text-sm mb-0">
                            {formik.errors.items[index]?.price}
                          </div>
                        )}
                    </div>
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`quantity${index}`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Quantity
                      </label>
                      <input
                        type="number"
                        min={0}
                        id={`items.${index}.quantity`}
                        name={`items.${index}.quantity`}
                        defaultValue={parseFloat(
                          formik.values.items[index]?.quantity
                        )}
                        onChange={formik.handleChange}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Quantity"
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.items &&
                        formik.errors.items &&
                        formik.touched.items[index]?.quantity &&
                        formik.errors.items[index]?.quantity && (
                          <div className="text-red-500 text-sm mb-0">
                            {formik.errors.items[index]?.quantity}
                          </div>
                        )}
                    </div>
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`items.${index}.total`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Total
                      </label>
                      <input
                        type="number"
                        id={`items.${index}.total`}
                        name={`items.${index}.total`}
                        defaultValue={parseFloat(
                          formik.values.items[index]?.total
                        )}
                        onChange={formik.handleChange}
                        value={calculateTotal(index)}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Total"
                        disabled
                      />
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        onClick={handleAddClick}
                        className="px-4 py-2 text-green-600 hover:text-green-800 focus:outline-none"
                      >
                        <FiPlusCircle className="w-5 h-5 mr-2" />
                      </button>

                      {index > 0 ? (
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => handleRemoveClick(index)}
                            className="text-red-600 hover:text-red-800 focus:outline-none"
                          >
                            <AiOutlineMinusCircle className="w-5 h-5" />
                          </button>
                        </div>
                      ): (  <div className="flex items-center">
                      {/* Empty space holder */}
                      <div className="w-5" />
                    </div>)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="total"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Bill Total
                </label>
                <input
                  type="number"
                  id="total"
                  name="total"
                  value={grandTotal() || 0}
                  className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Total"
                  disabled
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="purpose"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Purpose
                </label>
                <textarea
                  id="purpose"
                  className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Purpose..."
                  name="purpose"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  defaultValue={formik.values.purpose}
                />
                {formik.touched.purpose && formik.errors.purpose ? (
                  <div className="text-red-500 text-sm mb-0">
                    {formik.errors.purpose}
                  </div>
                ) : null}
              </div>
              <div className="mb-6">
                <label
                  htmlFor="bill_photo"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Add Attachment ( if any )
                </label>
                <div className="space-y-4">
                  <div className="grid gap-4 grid-cols-3">
                    {selectedImage && (
                      <div className="relative w-32 h-32 bg-gray-100 border border-gray-300 rounded-lg overflow-hidden">
                        <img
                          src={
                            selectedImage.preview || formik.values.bill_photo
                          }
                          alt="Selected bill"
                          className="object-cover w-full h-full"
                        />
                        <button
                          className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                          onClick={removeImage}
                        >
                          X
                        </button>
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="w-full"
                    name="bill_photo"
                  />
                  {formik.errors.bill_photo && formik?.touched.bill_photo ? (
                    <div className="text-red-500 text-sm mb-0">
                      {formik.errors.bill_photo}
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-green-400 text-white rounded"
                onClick={formik.handleSubmit}
              >
                {isEdit ? "Upadate" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Expenses;
