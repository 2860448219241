import React from "react";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import TodayActivity from "./TodayActivity";
import RecentLeave from "./RecentLeave";
Chart.register(...registerables);

const CombinedCharts = () => {
  // Sample data for Total Employee chart
  const totalEmployeeData = {
    labels: ["Dot Net", "ReactJS", "PHP/Laraval", "BDE"], // Updated labels
    datasets: [
      {
        data: [50, 30, 20, 40], // Updated data
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF9900"], // Updated colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF9900"], // Updated hover colors
      },
    ],
  };
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  // Sample data for Expenses chart
  const expensesData = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Expances",
        data: labels.map(() => Math.floor(Math.random() * 1000000)),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  // Chart options
  const totalEmployeeOptions = {
    plugins: {
      title: {
        display: true,
        text: "Total Employee according to Department",
        font: {
          size: "20",
        },
      },
    },
  };

  const expensesOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const menAndWomenRatio = {
    labels: ["Men", "Women"], // Updated labels
    datasets: [
      {
        data: [60, 40], // Updated data
        backgroundColor: ["#FF6384", "#36A2EB"], // Updated colors
        hoverBackgroundColor: ["#FF6384", "#36A2EB"], // Updated hover colors
      },
    ],
  };
  const menAndWomenRatioOption = {
    plugins: {
      title: {
        display: true,
        text: "Employees Structure",
        font: {
          size: "20",
        },
      },
    },
  };

  return (
    <>
      <div className="flex w-full mt-10 justify-between">
        {/* Total Employee Chart */}
        <div className="w-96 p-4 border shadow-2xl">
          <Pie data={totalEmployeeData} options={totalEmployeeOptions} />
        </div>

        <div className="w-96 border shadow-2xl rounded-lg">
          <Line data={expensesData} options={expensesOptions} />
        </div>

        <div className="w-96 border shadow-2xl rounded-lg">
          <Pie data={menAndWomenRatio} options={menAndWomenRatioOption} />
        </div>
      </div>
      <div className="mt-10">
        <h1 className="text-lg font-bold">Recent Leave</h1>
        <RecentLeave />
      </div>
    </>
  );
};

export default CombinedCharts;
