import React, { useEffect, useState } from "react";
import { useSidebar } from "../../helper/SidebarContext";
import { useDispatch, useSelector } from "react-redux";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { setUserActivities } from "../../redux/slices/inoutSlice";

import { ADMIN, ONE, TWO } from "../../config/constants";

const Header = () => {
  const { toggleSidebar } = useSidebar();
  const date = new Date().toLocaleDateString();
  const [userActivity, setUserActivity] = useState();
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString([], { hour12: true })
  );




  const updateTime = () => {
    let time = new Date().toLocaleTimeString([], { hour12: true });
    setCurrentTime(time);
  };


  setInterval(updateTime, 1000);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state?.authSlice?.userDetails?.userId);
  const userRole = useSelector(
    (state) => state?.authSlice?.userDetails?.userRole
  );

  async function getuserTime() {
    await ApiUtils.getUserActivity(userId)
      .then((res) => {
        if (res.data.isSuccess) {
          setUserActivity(res?.data?.data);
          dispatch(setUserActivities(res?.data?.data));
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      })
      .catch((err) => {
        return err;
      });
  }

  const handleOut = () => {
    ApiUtils.setUserActivity({ userId: userId, activity: TWO }).then((res) => {
      if (res.data.isSuccess) {
        Toaster(res.data.data, SUCCESS_TOASTIFY_TYPE);
        getuserTime();
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  const handleActivity = async () => {
    ApiUtils.setUserActivity({ userId: userId, activity: ONE }).then((res) => {
      if (res.data.isSuccess) {
        Toaster(res.data.data, SUCCESS_TOASTIFY_TYPE);
        getuserTime();
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
    getuserTime();
  };

  useEffect(() => {
    if (userRole !== ADMIN) {
      getuserTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 flex py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-start">
            <button
              onClick={toggleSidebar}
              aria-controls="logo-sidebar"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  fillRule="evenodd"
                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                />
              </svg>
            </button>
            <a href="/" className="flex ml-2 md:mr-24">
              <img src="https://www.tecmantras.com/imgs/logo.png" alt="logo" />
            </a>
          </div>

          {userRole !== ADMIN && (
            <div className="absolute grid grid-cols-2 gap-3 right-[2%] p-2">
              <div className="flex justify-end space-x-3 relative left-11 bottom-2 items-center">
                {userActivity === TWO && (
                  <button
                    onClick={handleActivity}
                    type="button"
                    className="text-white bg-green-600 w-28 hover:bg-green-900 h-8 rounded-full font-bold"
                  >
                    IN
                  </button>
                )}

                {userActivity === ONE && (
                  <button
                    onClick={handleOut}
                    type="button"
                    className="text-white bg-red-600 w-28 hover:bg-red-900 h-8 rounded-full font-bold"
                  >
                    OUT
                  </button>
                )}
              </div>

              <div className="flex items-center relative left-11 bottom-2 mr-3">
                <h2 className="text-gray-700 text-sm font-bold border-2 p-2 border-dashed rounded-lg dark:text-gray-300">
                  {`${date}, ${currentTime}`}
                </h2>
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Header;
