import React, { useEffect, useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { useSelector } from "react-redux";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import DatePicker from "react-datepicker";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useCallback } from "react";
import { debounce } from "../../helper/Debounce";
import moment from "moment";
import { ONE, TEN } from "../../config/constants";

const LeaveApply = () => {
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [leaveId, setLeaveId] = useState();
  const [reason, setReason] = useState("");
  const [startDate, setStartDate] = useState("");
  const [leaveType, setLeaveTyep] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [leaveList, setLeaveList] = useState();
  const [leaveBalnce, setLeaveBalance] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isEdit, setIsEdit] = useState();

  const formatedStartDate =
    startDateFilter !== "" ? moment(startDateFilter).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDateFilter !== "" ? moment(endDateFilter).format("YYYY-MM-DD") : "";

  const handleStartDateFilterChange = (date) => {
    setStartDateFilter(date);
  };

  const handleEndDateFilterChange = (date) => {
    setEndDateFilter(date);
  };

  const validateForm = () => {
    const errors = {};

    // Validate the leave type
    if (!formData.leave_type) {
      errors.leave_type = "Leave type is required";
    }

    // Validate the start and end dates
    if (!startDate) {
      errors.start = "Please select start date";
    }

    // Validate the end date
    if (!endDate) {
      errors.end = "Please select end date";
    }

    // Validate the reason for leave
    if (!formData.reason) {
      errors.reason = "Reason for leave is required";
    }

    // Update the formErrors state with the validation results
    setFormErrors(errors);

    // Return true if there are no errors, indicating that the form is valid
    return Object.keys(errors).length === 0;
  };

  const id = useSelector((state) => state?.authSlice?.userDetails);

  const [formData, setFormData] = useState({
    leave_type: "",
    reason: "",
    start: "",
    end: "",
    total_days: 0,
  });

  const handleCancelChange = (e) => {
    setReason(e.target.value);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setReason("");
    setFormErrors({});
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEndDate("");
    setStartDate("");
    setFormErrors("");
    setFormData({
      leave_type: "",
      reason: "",
      start: "",
      end: "",
      total_days: 0,
    });

    setIsEdit(false);
  };

  async function typeofLeaves() {
    await ApiUtils.leaveType()
      .then((res) => {
        setLeaveTyep(res.data);
      })
      .catch((err) => {
        return err;
      });
  }
  async function getLeavesList() {
    await ApiUtils.GetUserLeaves(
      `${id.userId}?Page=${currentPage}&Pagesize=${TEN}&searchValue=${searchValue}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    )
      .then((res) => {
        if (res.status === 200) {
          setLeaveList(
            res.data.data.listResponse ? res.data.data.listResponse : []
          );
          setTotalCount(
            res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
          );
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / TEN)
              : []
          );
        }
      })
      .catch((err) => {
        return err;
      });
  }
  async function getLeavesBalance() {
    await ApiUtils.getUserLeaveBalance(`${id.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setLeaveBalance(res.data);
        }
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    getLeavesList();
    getLeavesBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startDateFilter, endDateFilter]);

  const toggleModal = () => {
    setShowModal(true);
    setEndDate("");
    setStartDate("");
    setFormErrors("");
    setFormData({
      leave_type: "",
      reason: "",
      start: "",
      end: "",
      total_days: 0,
    });
    typeofLeaves();
    setIsEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error message for the field being changed
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: "" });
    }
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    updateTotalDays(event.target.value, endDate);
    setFormData({ ...formData, start: event.target.value });
    // Clear the error message for the 'start' field
    if (formErrors.start) {
      setFormErrors({ ...formErrors, start: "" });
    }
  };

  const handleCancelClick = (data) => {
    setShowPopup(true);
    setLeaveId(data);
    setIsEdit(true);
  };

  const handleEndDateChange = async (event) => {
    await setEndDate(event.target.value);
    await setFormData({ ...formData, end: event.target.value });
    await updateTotalDays(startDate, event.target.value);
    // Clear the error message for the 'start' field
    if (formErrors.end) {
      setFormErrors({ ...formErrors, end: "" });
    }
  };

  const updateTotalDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (startDate && endDate) {
      // Calculate the difference in days (including the end date)
      const timeDifference = endDate.getTime() - startDate.getTime();
      const totalDay = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
      setFormData((prevFormData) => ({
        ...prevFormData,
        total_days: totalDay,
      }));
    }
  };
  function formatDate(dateString) {
    const dateParts = dateString.split("-");
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];

    return `${day}-${month}-${year}`;
  }

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      const data = {
        userId: id.userId,
        leaveTypeId: formData.leave_type,
        fromDate: startDate,
        todate: endDate,
        numberOfDays: formData.total_days,
        reasonOfLeave: formData.reason,
      };
      const updateData = {
        userLeaveId: leaveId,
        userId: id.userId,
        leaveTypeId: Number(formData.leave_type),
        fromDate: startDate,
        todate: endDate,
        numberOfDays: formData.total_days,
        reasonOfLeave: formData.reason,
      };
      performOperation(isEdit, isEdit ? updateData : data)
        .then((res) => {
          if (res.data.isSuccess) {
            setShowModal(false);
            setFormData({
              leave_type: "",
              reason: "",
              start: "",
              end: "",
              total_days: 0,
            });
            getLeavesList();
            getLeavesBalance();
            Toaster(
              isEdit ? "Leave Updated Success" : "Leave Apply Success",
              SUCCESS_TOASTIFY_TYPE
            );
          } else {
            Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const handleEdit = (data) => {
    setShowModal(true);
    setIsEdit(true);
    typeofLeaves();
    setLeaveId(data.userLeaveId);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setFormData({
      leave_type:
        data.leaveType === "Flexi Leave"
          ? 4
          : data.leaveType === "Paid Leave"
          ? 1
          : 3,
      reason: data.reasonForLeave,
      start: startDate,
      end: endDate,
      total_days: data.totalDays,
    });
  };

  const handleSubmitCaancel = () => {
    const data = {
      userLeaveId: leaveId.userLeaveId,
      leaveStatus: 4,
      remark: reason,
      role: id.userRole,
    };
    ApiUtils.userCancelLeave(data).then((res) => {
      if (res.data.isSuccess) {
        Toaster(res.data.message, SUCCESS_TOASTIFY_TYPE);
        setShowPopup(false);
        setReason("");
        getLeavesList();
        getLeavesBalance();
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleSearch = async (value) => {

    if(leaveList?.length > 0 && value.length>=3){
      setSearchValue(value);
      await ApiUtils.GetUserLeaves(
        `${
          id.userId
        }?Page=${currentPage}&searchValue=${value}&PageSize=${TEN}&fromDate=${
          formatedStartDate || ""
        }&toDate=${formatedEndDate || ""}`
      )
        .then((res) => {
          if (res.status === 200) {
            setLeaveList(
              res.data.data.listResponse ? res.data.data.listResponse : []
            );
            setTotalCount(
              res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
            );
            setTotalRecords(
              res?.data?.data?.totalCount
                ? Math.ceil(res?.data?.data?.totalCount / 10)
                : []
            );
          }
        })
        .catch((err) => {
          return err;
        });
    }
  
  };


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [leaveList]);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const performOperation = (checkMode, params) =>
    checkMode ? ApiUtils.updateLeave(params) : ApiUtils.ApplyLeave(params);

  return (
    <React.Fragment>
      <div className="flex items-center gap-4">
        <h1 className="text-lg font-bold">LEAVE ARCHIVE</h1>
      </div>

      <div className="flex items-center justify-between mt-3 pb-3">
        <h1 className="font-bold text-red-400">
          Your Remaining Leaves:{" "}
          <span className="text-black">{leaveBalnce}</span>
        </h1>
        <div className="flex items-center gap-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for items"
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
          <div className="flex">
            <div className="flex">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                showIcon={true}
                todayButton="TODAY"
                selected={startDateFilter}
                onChange={handleStartDateFilterChange}
                placeholderText="Select start date"
                className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div className="flex">
              <label className="ml-2 mr-2">To:</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                showIcon
                todayButton="TODAY"
                selected={endDateFilter}
                onChange={handleEndDateFilterChange}
                placeholderText="Select end date"
                className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="text-white flex items-center gap-1 bg-green-600 p-5 hover:bg-green-900 h-8 rounded-full font-bold"
              onClick={toggleModal}
            >
              APPLY LEAVE
            </button>
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Leave Type
              </th>
              <th scope="col" className="px-6 py-3">
                Applied Date
              </th>
              <th scope="col" className="px-6 py-3">
                Start date - End date
              </th>
              <th scope="col" className="px-6 py-3">
                Total Days
              </th>

              <th scope="col" className="px-6 py-3">
                Reason for Leave
              </th>
              <th scope="col" className="px-6 py-3">
                Remarks
              </th>
              <th scope="col" className="px-6 py-3">
                Status by HR
              </th>
              <th scope="col" className="px-6 py-3">
                Status by Manager
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {leaveList?.length > 0 ? (
              leaveList?.map((list) => {
                return (
                  <tr
                    key={list.userLeaveId}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">{list.leaveType}</td>
                    <td className="px-6 py-4">
                      {formatDate(list.appliedDate)}
                    </td>
                    <td className="px-6 py-4">{`${formatDate(
                      list.startDate
                    )} to ${formatDate(list.endDate)}`}</td>
                    <td className="px-6 py-4">{list.totalDays}</td>
                    <td className="px-6 py-4">{list.reasonForLeave}</td>
                    <td className="px-6 py-4">{list.remark}</td>
                    <td className="px-6 py-4">
                      <span
                        className={
                          "inline-block py-1 px-3 rounded-full text-sm font-semibold " +
                          (list.statusByHR === 1
                            ? "text-yellow-300"
                            : list.statusByHR === 2
                            ? " text-green-300"
                            : list.statusByHR === 3
                            ? " text-red-500"
                            : list.statusByHR === 4
                            ? "text-gray-300"
                            : "")
                        }
                      >
                        {list.statusByHR === 1
                          ? "Pending"
                          : list.statusByHR === 2
                          ? "Approved"
                          : list.statusByHR === 3
                          ? "Rejected"
                          : list.statusByHR === 4
                          ? "Canceled"
                          : ""}
                      </span>
                    </td>
                    <td className="px-6 py-4">
                      <span
                        className={
                          "inline-block py-1 px-3 rounded-full text-sm font-semibold " +
                          (list.statusByManager === 1
                            ? "text-yellow-300"
                            : list.statusByManager === 2
                            ? " text-green-300"
                            : list.statusByManager === 3
                            ? " text-red-500"
                            : list.statusByManager === 4
                            ? "text-gray-300"
                            : "")
                        }
                      >
                        {list.statusByManager === 1
                          ? "Pending"
                          : list.statusByManager === 2
                          ? "Approved"
                          : list.statusByManager === 3
                          ? "Rejected"
                          : list.statusByManager === 4
                          ? "Canceled"
                          : ""}
                      </span>
                    </td>
                    <td className="px-6 py-4 flex gap-5">
                      {list.status !== 2 && list.status !== 3 && (
                        <>
                          {new Date(list.endDate) < new Date() ? (
                            "not allowed"
                          ) : list.status !== 4 ? (
                            <TooltipItem
                              tooltipsText="Cancel Leave"
                              onClick={() => handleCancelClick(list)}
                              className="text-lg text-red-500"
                            >
                              <MdOutlineCancel />
                            </TooltipItem>
                          ) : (
                            "Canceled"
                          )}
                          {new Date(list.endDate) > new Date() &&
                          list.status !== 4 ? (
                            <TooltipItem
                              onClick={() => handleEdit(list)}
                              className="text-lg text-green-500"
                              tooltipsText="Edit"
                            >
                              <FaEdit />
                            </TooltipItem>
                          ) : null}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center justify-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl  overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5 ">{`${
              isEdit ? "Update Leave" : "Apply For Leave"
            }`}</h2>

            <form>
              <div className="mb-6">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select Leave Type
                </label>

                <select
                  onChange={handleChange}
                  name="leave_type"
                  value={formData.leave_type}
                  placeholder="Choose a Leave Type"
                  className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                    formErrors.leave_type ? "border-red-500" : ""
                  }`}
                >
                  <option selected disabled value="">
                    Choose Leave Type
                  </option>
                  {leaveType.map((leaves) => {
                    return (
                      <option
                        key={leaves.leaveTypeId}
                        value={leaves.leaveTypeId}
                      >
                        {leaves.name}
                      </option>
                    );
                  })}
                </select>
                {formErrors.leave_type && (
                  <p className="text-red-500 text-sm">
                    {formErrors.leave_type}
                  </p>
                )}
              </div>
              <div className="grid gap-6 mb-4 md:grid-cols-3">
                <div className="col-span-2">
                  <label
                    htmlFor="countries"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Dates
                  </label>
                  <div date-rangepicker="" className="flex mb-4 items-center">
                    <div className="flex flex-col w-1/2">
                      <input
                        name="start"
                        type="date"
                        value={startDate}
                        min={getCurrentDate()}
                        onChange={handleStartDateChange}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          formErrors.start ? "border-red-500" : ""
                        }`}
                        placeholder="Select date start"
                      />
                      {formErrors.start && (
                        <div className="text-red-500 text-sm mt-1">
                          {formErrors.start}
                        </div>
                      )}
                    </div>

                    <span className="mx-4 text-gray-500">to</span>

                    <div className="flex flex-col w-1/2">
                      <input
                        name="end"
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        min={startDate}
                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          formErrors.end ? "border-red-500" : ""
                        }`}
                        placeholder="Select date end"
                      />
                      {formErrors.end && (
                        <div className="text-red-500 text-sm mt-1">
                          {formErrors.end}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="ml-auto">
                  <label
                    htmlFor="countries"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Total Days
                  </label>

                  <input
                    type="number"
                    value={formData.total_days}
                    disabled
                    name="total_days"
                    onChange={handleChange}
                    className="bg-gray-50 border cursor-no-drop text-gray-900 text-sm
                rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                w-32 p-2.5 dark:bg-gray-700 dark:border-gray-600
                dark:placeholder-gray-400 dark:text-white
                dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="reason"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Reason for Leave
                </label>
                <textarea
                  id="reason"
                  className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Reason..."
                  required
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                />
                {formErrors.reason && (
                  <p className="text-red-500 text-sm">{formErrors.reason}</p>
                )}
              </div>
            </form>

            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-green-400 text-white rounded"
              >
                {isEdit ? "Update" : "Apply"}
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow w-96">
            <h2 className="text-xl font-semibold mb-4">
              Enter Reason for Cancel
            </h2>
            <textarea
              className="w-full h-28 border rounded p-2 mb-4"
              value={reason}
              onChange={handleCancelChange}
            />
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-green-400 text-white rounded"
                onClick={handleSubmitCaancel}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LeaveApply;
