import React, { useEffect, useState } from "react";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiUtils from "../../api/ApiUtils";
import { ONE } from "../../config/constants";

const DepartmentList = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);

  const formik = useFormik({
    initialValues: {
      deparmentname: "",
    },
    validationSchema: Yup.object().shape({
      deparmentname: Yup.string().required("Department Name is Required"),
    }),
    onSubmit: (value) => {
      console.log("sdsdfsdfs", value);
    },
  });

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  async function getDepartments() {
    await ApiUtils.getDepartments()
      .then((res) => {
        setDepartments(res?.data);
      })
      .catch((err) => {
        return err;
      });
  }

  useEffect(() => {
    getDepartments();
    setTotalCount(20);
    setTotalRecords(10);
  }, []);

  const toggleModal = () => {
    setShowPopup((prev) => !prev);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <div className="container flex items-center mt-3 justify-between">
        <h1 className="text-lg font-bold">DEPARTMENT LIST</h1>
        <div>
          <button
            type="button"
            className="text-white bg-green-600 w-36 hover:bg-green-900 mb-1 h-auto rounded-full font-bold"
            onClick={toggleModal}
          >
            ADD DEPARTMENT
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                department name
              </th>
              <th scope="col" className="px-6 py-3">
                manager name
              </th>
              <th scope="col" className="px-6 py-3">
                employes
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {departments?.length > 0 ? (
              departments?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4 uppercase">{item.name}</td>
                    <td className="px-6 py-4">{item.manager}</td>
                    <td className="px-6 py-4">{item.employees}</td>

                    <td className="px-6 py-4 flex gap-5">
                      <TooltipItem
                        onClick={toggleModal}
                        className="text-lg text-green-500"
                        tooltipsText="Edit"
                      >
                        <FaEdit />
                      </TooltipItem>
                      <TooltipItem
                        className="text-lg text-red-500"
                        tooltipsText="Delete"
                      >
                        <AiFillDelete />
                      </TooltipItem>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl mt-12 overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5">Add Department</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="deparmentname"
                  className="block text-sm mb-2 font-medium text-gray-700"
                >
                  Department Name
                </label>
                <input
                  type="text"
                  id="deparmentname"
                  name="deparmentname"
                  value={formik.values.deparmentname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {formik.touched.deparmentname && formik.errors.deparmentname ? (
                  <div className="text-red-500 text-sm">
                    {formik.errors.deparmentname}
                  </div>
                ) : null}
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DepartmentList;
