import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { authRoutes, publicRoutes } from "./index";
import Layout from "../components/Layout/Layout";
import { useSelector } from "react-redux";
import { checkUserExists } from "../UserAuthByUserId";


const AppRoutes = () => {
  const token = useSelector((state) => state?.authSlice?.userDetails);
  const [auth, setAuth]=useState(true)
  



useEffect(() => {
  const fetchData = async () => {
      try {
          const userExists = await checkUserExists(); 
          setAuth(userExists);
      } catch (error) {
          console.error("Error fetching user data:", error);
          // Handle error state or logging as appropriate
      }
  };
  
  fetchData();
}, []);

  const isProtectedRoute = (token?.userToken && auth) ? true : false;
  
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            isProtectedRoute ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        {publicRoutes?.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                isProtectedRoute ? (
                  <Navigate
                    to="/dashboard"
                    replace
                    state={{ from: route.path }}
                  />
                ) : (
                  <route.component />
                )
              }
            />
          );
        })}

        {authRoutes?.map((route, idx) => {
          let id = idx * Math.floor(Math.random() * 10);
          return (
            <Route
              key={id}
              path={route.path}
              element={
                !isProtectedRoute ? (
                  <Navigate to="/login" replace state={{ from: route.path }} />
                ) : (
                  <Layout>
                    <route.component />
                  </Layout>
                )
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
