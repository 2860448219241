import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN, HR, ONE, TEN } from "../../config/constants";
import { useSelector } from "react-redux";
import ApiUtils from "../../api/ApiUtils";
import { useEffect } from "react";
import Swal from "sweetalert2";

const PolicyList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(ONE);
  const [totalCount, setTotalCount] = useState();
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [list, setList] = useState();

  const handlePolicyAdd = () => {
    navigate("/add-policy");
  };

  const handleEditClick = (index) => {
    navigate(`/edit-policy/${index.id}`);
  };
  const role = useSelector((state) => state?.authSlice?.userDetails?.userRole);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const getList = async () => {
    ApiUtils.getAllPolicy(`Page=${currentPage}&PageSize=${TEN}`).then((res) => {
      if (res.data.isSuccess) {
        setList(res?.data?.data?.listResponse);
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      }
    });
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const accordionData = list?.map((data) => {
    return {
      title: data.policyTitle,
      content: data.description,
      id: data.policyId,
    };
  });

  const handleDeleteClick = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ApiUtils.deleteAllPolicy(data.id)
          .then((res) => {
            if (res.data.isSuccess) {
              Swal.fire({
                title: "Deleted!",
                text: "Policy has been deleted.",
                icon: "success",
              });

              getList();
            } else {
              Swal.fire({
                title: "Error!",
                text: res.data.message,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            // Handle API call error
            Swal.fire({
              title: "Error!",
              text: "An error occurred while deleting the expense.",
              icon: "error",
            });
          });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-between">
        <h1 className="text-lg mt-2 mb-1 font-bold">POLICIES</h1>
        {role === ADMIN ||
          (role === HR && (
            <button
              className="text-white flex items-center gap-1 mt-2 mb-2 bg-green-600 p-5 hover:bg-green-900 h-8 rounded-full font-bold"
              onClick={handlePolicyAdd}
            >
              Add Policy
            </button>
          ))}
      </div>

      <div className="space-y-2">
        {accordionData?.map((item, index) => (
          <div key={index} className="border rounded-lg mb-4">
            <div className="bg-gray-50 rounded-xl w-full text-left px-4 py-3 border-b">
              <h2 className="text-lg font-semibold">{item.title}</h2>
              <hr className="mt-1 mb-1" />

              <div className="max-h-40 overflow-y-auto">
                <p
                  className="p-2"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></p>
              </div>

              {role !== "Employee" && (
                <div className="flex bg-gray-50 justify-end p-4">
                  <button
                    onClick={() => handleEditClick(item)}
                    className="text-blue-500 hover:text-blue-600 focus:outline-none mr-4"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteClick(item)}
                    className="text-red-500 hover:text-red-600 focus:outline-none"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center justify-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </React.Fragment>
  );
};

export default PolicyList;
