import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiUtils from "../../api/ApiUtils";

const Profile = ({ id }) => {
  const [userProfileData, setUserProfileData] = useState();
  const [leavesTaken, setLeavesTaken] = useState();
  console.log("🚀 ~ Profile ~ leavesTaken:", leavesTaken);

  const userProfile = () => {
    ApiUtils.getUserById(id).then((res) => {
      console.log("🚀 ~ ApiUtils.getUserById ~ res:", res);
      if (res?.data?.isSuccess) {
        setUserProfileData(res?.data?.data);
      }
    });
  };

  const userLeaveTaken = () => {
    ApiUtils.getLeaveCountOfYear(id).then((res) => {
      if (res.data.isSuccess) {
        setLeavesTaken(res);
      }
    });
  };

  useEffect(() => {
    userProfile();
    userLeaveTaken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-white p-4 rounded-lg h-full shadow-md">
      <form className="grid grid-cols-3 gap-4">
        <div>
          <label
            htmlfor="deparmentname"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            First Name:
          </label>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            id="firstName"
            value={userProfileData?.firstName}
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Last Name:
          </label>
          <input
            type="text"
            id="lastName"
            value={userProfileData?.lastName}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="mobile"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Mobile No.:
          </label>
          <input
            type="text"
            id="mobile"
            value={userProfileData?.phone}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="joiningDate"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Joining Date:
          </label>
          <input
            type="text"
            id="joiningDate"
            value={moment(userProfileData?.doj).format("DD-MM-YYYY")}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="leavesTaken"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Total Leaves Taken This Year:
          </label>
          <input
            type="text"
            id="leavesTaken"
            value={leavesTaken}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="gender"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Gender:
          </label>
          <input
            type="text"
            id="gender"
            value={userProfileData?.gender}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="department"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Department:
          </label>
          <input
            type="text"
            id="department"
            value={userProfileData?.department}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="manager"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Manager:
          </label>
          <input
            type="text"
            id="manager"
            value={userProfileData?.assignManager}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="role"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Role:
          </label>
          <input
            type="text"
            id="role"
            value={userProfileData?.role}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="dob"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Date of Birth:
          </label>
          <input
            type="text"
            id="dob"
            value={moment(userProfileData?.dob).format("DD-MM-YYYY")}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled
          />
        </div>
      </form>
    </div>
  );
};

export default Profile;
