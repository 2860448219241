import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
const Education = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [educationData, setEducationData] = useState();

  const educationDetails = () => {
    ApiUtils.getEducationDetails(id).then((res) => {
      if (res.data.isSuccess) {
        setEducationData(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    educationDetails();
  }, []);

  const initialValues = {
    university: educationData?.institute ? educationData.institute : "",
    degree: educationData?.degree ? educationData.degree : "",
    fieldOfStudy: educationData?.majorFieldStudy
      ? educationData.majorFieldStudy
      : "",
    startYear: educationData?.startYear ? educationData.startYear : "",
    completionYear: educationData?.endYear ? educationData.endYear : "",
  };
  const validationSchema = Yup.object().shape({
    university: Yup.string().required("University is required"),
    degree: Yup.string().required("Degree is required"),
    fieldOfStudy: Yup.string().required("Field of Study is required"),
    startYear: Yup.number()
      .typeError("Start Year must be a valid number")
      .required("Start Year is required")
      .integer("Start Year must be an integer")
      .min(1900, "Start Year must be greater than or equal to 1900")
      .max(new Date().getFullYear(), "Start Year cannot be in the future"),
    completionYear: Yup.number()
      .typeError("Completion Year must be a valid number")
      .required("Completion Year is required")
      .integer("Completion Year must be an integer")
      .min(Yup.ref("startYear"), "Completion Year must be after Start Year")
      .max(new Date().getFullYear(), "Completion Year cannot be in the future"),
  });
  const onSubmit = async (values, { setSubmitting }) => {
    const addData = {
      institute: values.university,
      degree: values.degree,
      majorFieldStudy: values.fieldOfStudy,
      startYear: values.startYear,
      endYear: values.completionYear,
      userId: id,
    };

    const updateData = {
      id: educationData?.id,
      institute: values.university,
      degree: values.degree,
      majorFieldStudy: values.fieldOfStudy,
      startYear: values.startYear,
      endYear: values.completionYear,
      userId: id,
    };

    performOperation(
      educationData?.id,
      educationData?.id ? updateData : addData
    )
      .then((res) => {
        if (res?.status === 200) {
          setIsEditing(false);
          setSubmitting(false);
          educationDetails();
        }
      })
      .catch((error) => {
        Toaster(error, "error");
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  const performOperation = (checkMode, params) =>
    checkMode
      ? ApiUtils.updateEducationDetails(params)
      : ApiUtils.createEducationDetails(params);

  return (
    <div className="bg-white p-4 rounded-lg h-full shadow-md">
      <form className="grid grid-cols-3 gap-4">
        <div>
          <label
            htmlFor="school"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Educational Institution/School:
          </label>
          <input
            type="text"
            id="school"
            name="university"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.university}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled={!isEditing}
          />
          {formik.touched.university && formik.errors.university ? (
            <div className="text-red-500 text-sm">
              {formik.errors.university}
            </div>
          ) : null}
        </div>
        <div>
          <label
            htmlFor="degree"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Degree/Qualification:
          </label>
          <input
            type="text"
            id="degree"
            name="degree"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.degree}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled={!isEditing}
          />
          {formik.touched.degree && formik.errors.degree ? (
            <div className="text-red-500 text-sm">{formik.errors.degree}</div>
          ) : null}
        </div>
        <div>
          <label
            htmlFor="fieldOfStudy"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Field of Study/Major:
          </label>
          <input
            type="text"
            id="fieldOfStudy"
            name="fieldOfStudy"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.fieldOfStudy}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled={!isEditing}
          />
          {formik.touched.fieldOfStudy && formik.errors.fieldOfStudy ? (
            <div className="text-red-500 text-sm">
              {formik.errors.fieldOfStudy}
            </div>
          ) : null}
        </div>
        <div>
          <label
            htmlFor="startYear"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Start Year:
          </label>
          <input
            type="text"
            id="startYear"
            name="startYear"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.startYear}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled={!isEditing}
          />
          {formik.touched.startYear && formik.errors.startYear ? (
            <div className="text-red-500 text-sm">
              {formik.errors.startYear}
            </div>
          ) : null}
        </div>
        <div>
          <label
            htmlFor="completionYear"
            className="block text-sm mb-2 text-gray-700 font-semibold"
          >
            Year of Graduation/Completion:
          </label>
          <input
            type="text"
            id="completionYear"
            name="completionYear"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            defaultValue={formik.values.completionYear}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            disabled={!isEditing}
          />
          {formik.touched.completionYear && formik.errors.completionYear ? (
            <div className="text-red-500 text-sm">
              {formik.errors.completionYear}
            </div>
          ) : null}
        </div>
      </form>
      <div className="flex justify-end mt-4">
        {!isEditing ? (
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleEdit}
          >
            EDIT
          </button>
        ) : (
          <>
            <button
              type="button"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleSave}
            >
              SAVE
            </button>
            <button
              type="button"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setIsEditing(false)}
            >
              CANCEL
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Education;
