import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import { useFormik } from "formik";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { useNavigate, useParams } from "react-router-dom";

const EditPolicy = () => {
  const navigate = useNavigate();
  const policyId = useParams().id;
  const [policyData, setPolicyData] = useState();

  useEffect(() => {
    if (!policyId) {
      navigate("/add-policy");
    } else {
      ApiUtils.getPolicyById(policyId)
        .then((res) => {
          if (res.data.isSuccess) {
            setPolicyData(res.data.data);
          } else {
            Toaster("Failed to retrieve policy data", ERROR_TOASTIFY_TYPE);
            navigate("/policy-list");
          }
        })
        .catch((error) => {
          // Handle unexpected errors, e.g., network issues
          Toaster(
            "An error occurred while retrieving policy data",
            ERROR_TOASTIFY_TYPE
          );
          navigate("/policy-list");
        });
    }
  }, [navigate, policyId]);

  const formik = useFormik({
    initialValues: {
      policy_title: "",
      user_type: [],
      policy_description: "",
    },
    validationSchema: Yup.object().shape({
      policy_title: Yup.string().required("Title is required"),
      //   user_type: Yup.array()
      //     .of(Yup.string().required("User type is required"))
      //     .required("At least one user type is required"),
      policy_description: Yup.string().required(
        "Policy description is required"
      ),
    }),

    onSubmit: (values) => {
      const data = {
        policyTitle: values.policy_title,
        description: values.policy_description,
        policyId: policyId,
      };
      ApiUtils.updatePolicy(data).then((res) => {
        if (res.data.isSuccess) {
          Toaster("Policy Updated", SUCCESS_TOASTIFY_TYPE);
          navigate("/policy");
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      policy_title: policyData?.policyTitle,
      //   user_type: policyData.user_type,
      policy_description: policyData?.description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyData]);

  return (
    <div>
      <h1 className="text-2xl mb-4 font-bold">EDIT POLICY</h1>
      <hr className="mb-6" />
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-6">
          <label
            htmlFor="policy_title"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Policy Title
          </label>
          <input
            id="policy_title"
            type="text"
            name="policy_title"
            className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Policy Title...."
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.policy_title}
          />
          {formik.errors.policy_title && formik.touched.policy_title ? (
            <div className="text-red-500 text-sm">
              {formik.errors.policy_title}
            </div>
          ) : null}
        </div>
        <div className="mb-6">
          <label
            htmlFor="countries"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Policy Description
          </label>
          <CKEditor
            config={{
              extraPlugins: [],
            }}
            editor={ClassicEditor}
            onReady={(editor) => {}}
            onBlur={(event, editor) => {}}
            onFocus={(event, editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik.setFieldValue("policy_description", data);
            }}
            data={formik.values.policy_description}
            style={{
              maxWidth: "400px",
              wordWrap: "break-word",
            }}
          />
          {formik.errors.policy_description &&
          formik.touched.policy_description ? (
            <div className="text-red-500 text-sm">
              {formik.errors.policy_description}
            </div>
          ) : null}
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700"
          >
            UPDATE POLICY
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPolicy;
