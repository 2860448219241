import React, { useEffect, useState } from "react";
import ApiUtils from "../../api/ApiUtils";

const LeaveReport = ({ userId }) => {
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [rejectedLeaveReason, setRejectedLeaveReason] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [leaveList, setLeaveList] = useState([]);

  const getLeaveList = () => {
    ApiUtils.GetUserLeaves(`${userId}?page=1&pageSize=10`).then((res) => {
      if (res?.data?.isSuccess) {
        setLeaveList(res?.data?.data?.listResponse);
      }
    });
  };

  useEffect(() => {
    getLeaveList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewReason = (reason) => {
    setRejectedLeaveReason(reason);
    setShowReasonModal(true);
  };

  const handleExportCSV = () => {
    // Convert leave data to CSV format
    const csvContent = [
      Object.keys(leaveList[0]).join(","),
      ...leaveList?.map((item) => Object.values(item).join(",")),
    ].join("\n");

    // Create a Blob containing the CSV data
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a temporary URL to the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "leave_data.csv");

    // Simulate clicking the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleYearFilterChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const filteredLeaveData = selectedYear
    ? leaveList.filter((item) => item.startDate.startsWith(selectedYear))
    : leaveList;

  return (
    <div className="bg-white p-4 rounded-lg h-full shadow-md">
      <div className="flex justify-end gap-3 mb-2">
        <div>
          <button
            className="right-4 px-4 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleExportCSV}
          >
            Export to CSV
          </button>
        </div>
        <select
          value={selectedYear}
          onChange={handleYearFilterChange}
          className="px-2 py-1 border border-gray-300 rounded-md"
        >
          <option value="" disabled>
            Select Year
          </option>
          <option value="2024" selected={true}>
            2024
          </option>
          {/* Add more years as needed */}
        </select>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase bg-gray-700 text-gray-200">
            <tr>
              <th className="py-2 px-4">Leave Type</th>
              <th className="py-2 px-4">Start Date to End Date</th>
              <th className="py-2 px-4">Reason</th>
              <th className="py-2 px-4">Days</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Remark</th>
            </tr>
          </thead>
          <tbody>
            {filteredLeaveData?.length > 0 ? (
              filteredLeaveData?.map((leave) => (
                <tr
                  key={leave.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="border px-4 py-2">{leave.leaveType}</td>
                  <td className="border px-4 py-2">
                    {leave.startDate} to {leave.endDate}
                  </td>
                  <td className="border px-4 py-2">{leave.reasonForLeave}</td>
                  <td className="border px-4 py-2">{leave.totalDays}</td>
                  <td className="border px-4 py-2">{leave.status}</td>
                  <td className="border px-4 py-2">
                    {leave.status === 2 ? (
                      <button
                        className="text-blue-400 underline"
                        onClick={() => handleViewReason(leave.remark)}
                      >
                        View
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showReasonModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Reason for Rejection
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {rejectedLeaveReason}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowReasonModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveReport;
