import LeaveRequests from "../pages/Manager-HR/LeaveRequests";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import Login from "../pages/Authentication/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import LeaveApply from "../pages/User/LeaveApply";
import UserList from "../pages/User/UserList";
import PolicyList from "../pages/Policy/PolicyList";
import AddPolicy from "../pages/Policy/AddPolicy";
import Expenses from "../pages/Manager-HR/Expenses";
import EarlyGoing from "../pages/User/EarlyGoing";
import InterviewSchedule from "../pages/Interview/InterviewSchedule";
import FestivalLeaves from "../pages/FestivalLeaves/FestivalLeaves";
import InOut from "../pages/User/InOut";
import EmployeeActivity from "../pages/Manager-HR/EmployeeActivity";
import SetInterview from "../pages/Interview/SetInterview";
import interviews from "../pages/Interview/interviews";
import UsersByManager from "../pages/Manager-HR/UsersByManager";
import EmployeeEarlyGoing from "../pages/Manager-HR/EmployeeEarlyGoing";
import Managers from "../pages/Manageres/Managers";
import EditPolicy from "../pages/Policy/EditPolicy";
import UserDetails from "../pages/userDetails/UserDetails";
import ConfirmationPage from "../pages/ConfirmationPage";
import HRLists from "../pages/HR/HRLists";
import Departments from "../pages/Department/DepartmentList";
import Payslip from "../pages/Payslip/Payslip";
import ApprovalUpdateTiming from "../pages/Manager-HR/ApprovalUpdateTiming";
import Calender from "../pages/Calender/Calender";
import CreatePaySlip from "../pages/Payslip/CreatePaySlip";

const authRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/user-list", component: UserList },
  { path: "/leave-management", component: LeaveApply },
  { path: "/leave-requests", component: LeaveRequests },
  { path: "/policy", component: PolicyList },
  { path: "/add-policy", component: AddPolicy },
  { path: "/expenses", component: Expenses },
  { path: "/early-going-late-coming", component: EarlyGoing },
  { path: "/recruitment", component: InterviewSchedule },
  { path: "/festival-leaves", component: FestivalLeaves },
  { path: "/in-out", component: InOut },
  { path: "/employee-activity", component: EmployeeActivity },
  { path: "/recruitment/:id", component: SetInterview },
  { path: "/interviews", component: interviews },
  { path: "/users-list", component: UsersByManager },
  { path: "/early-going-late-coming-employees", component: EmployeeEarlyGoing },
  { path: "/managers", component: Managers },
  { path: "/edit-policy/:id", component: EditPolicy },
  { path: "/hr-list", component: HRLists },
  { path: "/departments", component: Departments },
  { path: "/user-details/:id", component: UserDetails },
  { path: "/payslip", component: Payslip },
  { path: "/approvalUpdateTiming", component: ApprovalUpdateTiming },
  { path: "/calender", component: Calender },
  { path: "/payslips", component: CreatePaySlip },
];
const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/mail-confirmation", component: ConfirmationPage },
];

export { authRoutes, publicRoutes };
