import React, { useEffect, useState } from "react";

import Widgets from "./Widgets";
import CombinedCharts from "./CombinedCharts";

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState([]);

  useEffect(() => {
    // Fetch data from localStorage
    const adminUserData = localStorage.getItem("user");
    if (adminUserData) {
      // Parse the JSON data if available
      const adminEvents = JSON.parse(adminUserData);
      setCurrentUser(adminEvents);
    }
  }, []);
  return (
    <div className="dashboard mt-5">
      <Widgets />
      <CombinedCharts />
    </div>
  );
};

export default Dashboard;
