import React, { useEffect } from "react";
import { useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { AiFillDelete, AiOutlineMinusCircle } from "react-icons/ai";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import { ONE, TEN } from "../../config/constants";

const FestivalLeaves = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState([]);
  const [leaveId, setLeaveId] = useState();
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [items, setItems] = useState([
    { festival_name: "", date: "", type: "fixed" },
  ]);
  const [validationErrors, setValidationErrors] = useState({});

  async function getFetival() {
    ApiUtils.getFestivalLeaves(`?Page=${currentPage}&PageSize=${TEN}`)
      .then((res) => {
        if (res.data.isSuccess) {
          setList(res.data.data.listResponse ? res.data.data.listResponse : []);
          setTotalCount(res?.data?.data?.totalCount);
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / 10)
              : []
          );
        }
      })
      .catch((err) => {
        Toaster(err, ERROR_TOASTIFY_TYPE);
      });
  }

  useEffect(() => {
    getFetival();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleDelete = (data) => {
    ApiUtils.deleteFestivalLeaves(data).then((res) => {
      if (res.data.isSuccess) {
        Toaster("Leave Deleted Success", SUCCESS_TOASTIFY_TYPE);
        getFetival();
      }
    });
  };

  const toggleModal = () => {
    setShowPopup((prevState) => !prevState);
    setItems([{ festival_name: "", date: "", type: "fixed" }]);
    setEdit(false);
    setValidationErrors({});
  };

  const handleAddClick = () => {
    setItems([...items, { festival_name: "", date: "", type: "fixed" }]);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...items];
    newItems[index][name] = value;
    setItems(newItems);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [`${name}${index}`]: "",
    }));
  };

  const handleRemoveClick = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setEdit(false);
    setLeaveId("");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent form submission before validation

    const newValidationErrors = {};

    items.forEach((item, index) => {
      if (!item.festival_name) {
        newValidationErrors[`festival_name${index}`] =
          "Festival Name is required";
      }
      if (!item.date) {
        newValidationErrors[`date${index}`] = "Date is required";
      }
      // Add more validation rules as needed for other fields
    });

    // Check if there are any validation errors
    if (Object.keys(newValidationErrors).length === 0) {
      // Proceed with form submission
      const formData = items?.map((item) => ({
        festivalName: item.festival_name,
        festivalDate: item.date,
        isFloatingLeave: item.type === "fixed" ? false : true,
      }));

      const updateData = items?.map((item) => ({
        festivalName: item.festival_name,
        festivalDate: item.date,
        isFloatingLeave: item.type === "fixed" ? false : true,
        festivalLeaveId: leaveId,
      }));

      performOperation(edit, edit ? updateData : formData)
        .then((res) => {
          if (res.data.isSuccess) {
            Toaster("Leaves Added Successfully", SUCCESS_TOASTIFY_TYPE);
            getFetival();
            setItems([{ festival_name: "", date: "", type: "fixed" }]);
            setShowPopup(false);
          }
        })
        .catch((err) => {
          Toaster(err, ERROR_TOASTIFY_TYPE);
        });
    } else {
      // Display validation errors to the user
      setValidationErrors(newValidationErrors);
    }
  };

  const handleEdit = (data) => {
    setLeaveId(data.festivalLeaveId);
    const festivalDate = data.festivalDate.split("T")[0];
    const editedItem = {
      festival_name: data.festivalName,
      date: festivalDate,
      type: data.isFloatingLeave ? "optional" : "fixed",
    };

    const itemIndex = items.findIndex(
      (item) => item.festival_name === data.festivalName
    );

    // If the item to edit exists in the current items state, replace it
    if (itemIndex !== -1) {
      const newItems = [...items];
      newItems[itemIndex] = editedItem;
      setItems(newItems);
    } else {
      // If the item is not in the current items state, add it
      setItems([editedItem]);
    }

    setShowPopup(true); // Open the popup
    setEdit(true); // Set the edit mode to true
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  function getDayOfWeek(inputDate) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(inputDate);
    const dayOfWeek = daysOfWeek[date.getDay()];
    return dayOfWeek;
  }
  const performOperation = (checkMode, params) =>
    !checkMode
      ? ApiUtils.addFestivalLeaves(params)
      : ApiUtils.updateFestivalLeaves(params);

  return (
    <React.Fragment>
      <div className="container flex items-center mt-3 justify-between">
        <h1 className="text-lg font-bold">FESTIVAL LEAVES</h1>
        <div>
          <button
            type="button"
            className="text-white bg-green-600 w-36 hover:bg-green-900 mb-1 h-8 rounded-full font-bold"
            onClick={toggleModal}
          >
            ADD LEAVES
          </button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Day
              </th>
              <th scope="col" className="px-6 py-3">
                Festival
              </th>
              <th scope="col" className="px-6 py-3">
                Type
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td className="px-6 py-4">
                      {formatDate(data.festivalDate)}
                    </td>
                    <td className="px-6 py-4">
                      {getDayOfWeek(data.festivalDate)}
                    </td>
                    <td className="px-6 py-4">{data.festivalName}</td>
                    <td className="px-6 py-4">
                      {data.isFloatingLeave === true
                        ? "Optional Leave"
                        : "Fixed Leave"}
                    </td>
                    <td className="px-6 py-4 gap-5">
                      <div className="flex items-center gap-4">
                        <TooltipItem
                          onClick={() => handleEdit(data)}
                          className="text-lg text-green-500"
                          tooltipsText="Edit"
                        >
                          <FaEdit />
                        </TooltipItem>
                        <TooltipItem
                          onClick={() => handleDelete(data.festivalLeaveId)}
                          className="text-lg text-red-500"
                          tooltipsText="Delete"
                        >
                          <AiFillDelete />
                        </TooltipItem>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl  overflow-y-auto">
            <h2 className="text-xl font-semibold mb-4">
              {`${edit ? "Update Festival Leaves" : "Add Festival Leaves"}`}
            </h2>

            <form>
              <div>
                {items?.map((item, index) => (
                  <div key={index} className="flex mb-6 justify-between">
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`festival_name${index}`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Festival Name
                      </label>
                      <input
                        type="text"
                        id={`festival_name${index}`}
                        name="festival_name"
                        value={item.festival_name}
                        onChange={(e) => handleInputChange(index, e)}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Festival Name"
                      />

                      {validationErrors[`festival_name${index}`] && (
                        <div className="text-red-500 text-sm">
                          {validationErrors[`festival_name${index}`]}
                        </div>
                      )}
                    </div>
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`date${index}`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Date
                      </label>
                      <input
                        type="date"
                        id={`date${index}`}
                        name="date"
                        value={item.date}
                        onChange={(e) => handleInputChange(index, e)}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Price"
                      />
                      {validationErrors[`date${index}`] && (
                        <div className="text-red-500 text-sm">
                          {validationErrors[`date${index}`]}
                        </div>
                      )}
                    </div>
                    <div className="mr-4 w-full">
                      <label
                        htmlFor={`type${index}`}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Quantity
                      </label>
                      <select
                        id={`type${index}`}
                        name="type"
                        defaultValue={item.type}
                        onChange={(e) => handleInputChange(index, e)}
                        className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <>
                          <option value="fixed">Fixed Leave</option>
                          <option value="optional">Optional Leave</option>
                        </>
                      </select>
                    </div>

                    <div className="mt-6 flex">
                      <button
                        type="button"
                        onClick={handleAddClick}
                        className="px-4 py-2 text-green-600 hover:text-green-800 focus:outline-none"
                      >
                        <FiPlusCircle className="w-5 h-5 mr-2" />
                      </button>
                      {index > 0 ? (
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => handleRemoveClick(index)}
                            className="text-red-600 hover:text-red-800 focus:outline-none"
                          >
                            <AiOutlineMinusCircle className="w-5 h-5" />
                          </button>
                        </div>
                      ): (
                        <div className="w-5 h-5"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </form>
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleFormSubmit}
                className="px-4 py-2 bg-green-400 text-white rounded"
              >
                {`${edit ? "Update" : "Submit"}`}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FestivalLeaves;
