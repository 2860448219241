import { ADMIN, EMPLOYEE, HR, MANAGER } from "../config/constants";
import { FiUsers, FiSettings, FiActivity } from "react-icons/fi";
import { AiOutlineDashboard, AiOutlineEye } from "react-icons/ai";
import { RiBillLine, RiUserSearchLine } from "react-icons/ri";
import { FaCalendarAlt, FaWallet } from "react-icons/fa";

const sidebarItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    accessTo: [HR, ADMIN, EMPLOYEE, MANAGER],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "User List",
    path: "/user-list",
    accessTo: [HR, ADMIN],
    icon: <FiUsers />,
  },
  {
    title: "Departments",
    path: "/departments",
    accessTo: [HR, ADMIN],
    icon: <FiUsers />,
  },
  {
    title: "User List",
    path: "/users-list",
    accessTo: [MANAGER],
    icon: <FiUsers />,
  },
  {
    title: "Leave Approve",
    path: "/leave-requests",
    accessTo: [HR, ADMIN, MANAGER],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "HR Expenses",
    path: "/expenses",
    accessTo: [HR, ADMIN],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "Recruitment",
    path: "/recruitment",
    accessTo: [HR, ADMIN],
    icon: <RiUserSearchLine />,
  },
  {
    title: "Leave Apply",
    path: "/leave-management",
    accessTo: [HR, EMPLOYEE, MANAGER],
    icon: <FiSettings />,
  },
  {
    title: "Festival Leaves",
    path: "/festival-leaves",
    accessTo: [HR, ADMIN],
    icon: <RiBillLine />,
  },
  {
    title: "Early Going / Late Coming",
    path: "/early-going-late-coming",
    accessTo: [HR, EMPLOYEE, ADMIN, MANAGER],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "Employee Early Going / Late Coming",
    path: "/early-going-late-coming-employees",
    accessTo: [HR, ADMIN, MANAGER],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "Timings",
    path: "/in-out",
    accessTo: [HR, EMPLOYEE, MANAGER],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "Interviews",
    path: "/interviews",
    accessTo: [ADMIN, EMPLOYEE, MANAGER],
    icon: <AiOutlineDashboard />,
  },
  {
    title: "Calender",
    path: "/calender",
    accessTo: [ADMIN, EMPLOYEE, MANAGER, HR],
    icon: <FaCalendarAlt />,
  },
  {
    title: "View Policy",
    path: "/policy",
    accessTo: [HR, ADMIN, EMPLOYEE, MANAGER],
    icon: <AiOutlineEye />,
  },
  {
    title: "Payslip",
    path: "/payslip",
    accessTo: [EMPLOYEE],
    icon: <FaWallet />,
  },
  {
    title: "Payslips",
    path: "/payslips",
    accessTo: [HR, ADMIN],
    icon: <FaWallet />,
  },
  {
    title: "Managers",
    path: "/managers",
    accessTo: [HR, ADMIN],
    icon: <AiOutlineEye />,
  },
  // {
  //   title: "HR List",
  //   path: "/hr-list",
  //   accessTo: [ADMIN],
  //   icon: <AiOutlineEye />,
  // },
  {
    title: "Employee Activity",
    path: "/employee-activity",
    accessTo: [HR, ADMIN, MANAGER],
    icon: <FiActivity />,
  },
  {
    title: "Approval Time",
    path: "/approvalUpdateTiming",
    accessTo: [HR, ADMIN, MANAGER],
    icon: <FiActivity />,
  },
];

export default function getSidebarItems(userRole) {
  let resultedSidebarItems = sidebarItems.filter((item) => {
    return item.accessTo.includes(userRole);
  });
  return resultedSidebarItems;
}
