import React, { useEffect, useState } from "react";
import ApiUtils from "../../api/ApiUtils";
import { useSelector } from "react-redux";
import Toaster from "../../helper/Toaster";
import {
  ERROR_TOASTIFY_TYPE,
  SUCCESS_TOASTIFY_TYPE,
  WARNING_TOASTIFY_TYPE,
} from "../../helper/enum";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment-timezone";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { ONE, TEN } from "../../config/constants";

const InOut = () => {
  const id = useSelector((state) => state?.authSlice?.userDetails?.userId);
  const userActivities = useSelector(
    (state) => state?.inoutSlice?.userActivities
  );
  const [list, setList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(ONE);
  const [totalCount, setTotalCount] = useState();
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timingData, setTimingData] = useState();

  const [currentTarget, setCurrentTarget] = useState({});

  const [inputErrors, setInputErrors] = useState({});
  const [anotherInputErrors, setAnotherInputErrors] = useState({});

  const [finalData, setFinalData] = useState([]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  async function getInOutList() {
    ApiUtils.getUserActivityList(
      `userId=${id}&PageSize=${TEN}&Page=1&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    ).then((res) => {
      setList(
        res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
      );
      setTotalCount(
        res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
      );
      setTotalRecords(
        res?.data?.data?.totalCount
          ? Math.ceil(res?.data?.data?.totalCount / TEN)
          : []
      );
    });
  }

  const handleChange = (data, value, time, date, ID) => {
    setCurrentTarget({ data, value, time, date, ID });
    const formattedDate = date.split("/").reverse().join("-");

    const inputDateTime = date + " " + time;
    const parsedDateTime = moment(inputDateTime, "DD/MM/YYYY HH:mm");
    const utcFormattedDateTime = parsedDateTime
      .utc()
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const employeeId = data;

    const previousActivity = timingData.find(
      (item) => item.employeeActivityId === employeeId - 1
    );
    const currentActivity = timingData.find(
      (item) => item.employeeActivityId === employeeId
    );

    const nexttActivity = timingData.find(
      (item) => item.employeeActivityId === employeeId + 1
    );

    if (previousActivity || currentActivity || nexttActivity) {
      setFinalData((prev) => {
        const updatedData = Array.isArray(prev) ? [...prev] : [];

        const existingEntryIndex = updatedData.findIndex(
          (entry) =>
            entry?.employeeActivityId === data &&
            entry?.activity === (value === "Intime" ? 1 : 2)
        );

        if (existingEntryIndex !== -1) {
          updatedData[existingEntryIndex] = {
            userId: ID,
            activity: value === "Intime" ? 1 : 2,
            // activityTime: utcFormattedDateTime,
            [value === "Intime" ? "activityInTime" : "activityOutTime"]:
              utcFormattedDateTime,
            employeeActivityId: data,
            activityDate: formattedDate,
          };
        } else {
          const newEntry = {
            userId: ID,
            activity: value === "Intime" ? 1 : 2,
            // activityTime: utcFormattedDateTime,
            [value === "Intime" ? "activityInTime" : "activityOutTime"]:
              utcFormattedDateTime,
            employeeActivityId: data,
            activityDate: formattedDate,
          };
          updatedData.push(newEntry);
        }

        return updatedData;
        // finalDataLogic();
      });
    }
  };

  // convert the date and time of currentTarget in utc format ==========================
  const { date, time } = currentTarget;

  // Combine date and time
  const currentInputDateTime = date + " " + time;

  // Parse the combined date and time using moment.js
  const parsedDateTime = moment(currentInputDateTime, "DD/MM/YYYY HH:mm");

  // Convert to the Asia/Kolkata timezone
  const currentTargetUtcFormattedDateTime = parsedDateTime
    .utc()
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  //  ==========================================

  useEffect(() => {
    // filter out the finalData by comparing the id in both finalData and currentTarget

    const filterFinal = Object.values(finalData).filter(
      (e) => e?.employeeActivityId === currentTarget?.data - 1
    );

    // find out the timingData by comparing the id in both timingData and currentTarget
    const filterTimingData =
      timingData &&
      timingData.find((e) => currentTarget?.data === e?.employeeActivityId);

    // Find the id previous of the current id "in" finalData

    const findFinalFilter = Object.values(finalData).filter(
      (e) => e?.employeeActivityId === currentTarget?.data - 1
    );

    // Find the id previous of the current id "in" timingData
    const findTimingFilter =
      timingData &&
      timingData.find((e) => e?.employeeActivityId === currentTarget?.data - 1);

    // find the id next of the current id "out" finalData

    const findInFinal = Object.values(finalData).filter(
      (e) => e?.employeeActivityId === currentTarget?.data + 1
    );

    // find the id next for the current id "out" timingData
    const findTimingFilterOut =
      timingData &&
      timingData.find((e) => e?.employeeActivityId === currentTarget?.data + 1);

    // Check for current Intime with final outTime

    if (currentTarget.value === "Intime") {
      const outTimeFilterFinalIn = filterFinal?.find(
        (item) => item.activity === 2
      );
      // Case 1 to check same ID
      const data11 = currentTarget.data;
      //  case A check when change with IN TIME with same ID

      if (!!outTimeFilterFinalIn) {
        if (
          currentTargetUtcFormattedDateTime >=
            (outTimeFilterFinalIn && outTimeFilterFinalIn?.activityTime) &&
          outTimeFilterFinalIn !== undefined
        ) {
          setInputErrors((prevError) => ({
            ...prevError,
            [`inTime${data11}`]: "time is less then same out time",
          }));
        } else {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            [`inTime${data11}`]: "",
          }));
        }
      } else {
        //  case B check when database with IN TIME with same ID

        if (
          currentTargetUtcFormattedDateTime >=
          moment(filterTimingData?.outTime).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        ) {
          setInputErrors((prevError) => ({
            ...prevError,
            [`inTime${data11}`]: "time is less then same out time",
          }));
        } else {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            [`inTime${data11}`]: "",
          }));
        }
      }
      // Case 1 to check Next IN ID
      //  case A check when change with OUT TIME with same ID
      if (findFinalFilter.length > 0) {
        const findFinalFilterByActivity = findFinalFilter.find(
          (e) => e.activity === 2
        );

        if (
          findFinalFilterByActivity?.activityTime >=
          currentTargetUtcFormattedDateTime
        ) {
          // setInputErrors((prevError) => ({
          //   ...prevError,
          //   [`inTime${data11}`]: "time is larget than previous out time",
          // }));
          setAnotherInputErrors((prevError) => ({
            ...prevError,
            [`inTime${data11}`]: "time previous out time",
          }));
        } else {
          // setInputErrors((prevErrors) => ({
          //   ...prevErrors,
          //   [`inTime${data11}`]: "",
          // }));
          setAnotherInputErrors((prevErrors) => ({
            ...prevErrors,
            [`inTime${data11}`]: "",
          }));
        }
      } else {
        //  case B check when database with IN TIME with previous OUT ID

        if (
          currentTargetUtcFormattedDateTime <=
            moment(findTimingFilter?.outTime).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ) &&
          findTimingFilter !== undefined
        ) {
          // setInputErrors((prevError) => ({
          //   ...prevError,
          //   [`inTime${data11}`]: "time is larget than previous out time",
          // }));

          setAnotherInputErrors((prevError) => ({
            ...prevError,
            [`inTime${data11}`]: "time is larger than previous out time",
          }));
        } else {
          // setInputErrors((prevErrors) => ({
          //   ...prevErrors,
          //   [`inTime${data11}`]: "",
          // }));
          setAnotherInputErrors((prevErrors) => ({
            ...prevErrors,
            [`inTime${data11}`]: "",
          }));
        }
      }
    }

    // Out Time Validation--------------
    if (currentTarget.value === "OutTime") {
      const outTimeFilterFinal = filterFinal.find(
        (item) => item.activity === 1
      );
      const data22 = currentTarget.data;

      // Case 1 to check same ID
      //  case A check when change with OUT TIME with same ID
      if (!!outTimeFilterFinal) {
        if (
          currentTargetUtcFormattedDateTime <=
          (outTimeFilterFinal && outTimeFilterFinal?.activityTime)
        ) {
          setInputErrors((prevError) => ({
            ...prevError,
            [`outTime${data22}`]: "time is larger then same in time",
          }));
        } else {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            [`outTime${data22}`]: "",
          }));
        }
      } else {
        //  case B check when database with OUT TIME with same ID
        if (
          currentTargetUtcFormattedDateTime <=
          moment(filterTimingData?.inTime)?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        ) {
          setInputErrors((prevError) => ({
            ...prevError,
            [`outTime${data22}`]: "time is larger then same in time",
          }));
        } else {
          setInputErrors((prevErrors) => ({
            ...prevErrors,
            [`outTime${data22}`]: "",
          }));
        }
      }

      // Case 2 to check next IN ID
      //  Case A check when change with OUT TIME with next IN ID

      if (findInFinal.length > 0) {
        const findInFinalByActivity = findInFinal.find((e) => e.activity === 1);

        if (
          findInFinalByActivity?.activityTime <=
          currentTargetUtcFormattedDateTime
        ) {
          // setInputErrors((prevError) => ({
          //   ...prevError,
          //   [`outTime${data22}`]: "time should be smaller  than next in time",
          // }));
          setAnotherInputErrors((prevError) => ({
            ...prevError,
            [`outTime${data22}`]: "time should be smaller than next in time",
          }));
        } else {
          // setInputErrors((prevErrors) => ({
          //   ...prevErrors,
          //   [`outTime${data22}`]: "",
          // }));
          setAnotherInputErrors((prevErrors) => ({
            ...prevErrors,
            [`outTime${data22}`]: "",
          }));
        }
      } else {
        //  Case B check when database with OUT TIME with next IN ID
        if (
          currentTargetUtcFormattedDateTime >=
          moment(findTimingFilterOut?.inTime)?.format(
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          )
        ) {
          // setInputErrors((prevError) => ({
          //   ...prevError,
          //   [`outTime${data22}`]: "time should be smaller  than next in time",
          // }));
          setAnotherInputErrors((prevError) => ({
            ...prevError,
            [`outTime${data22}`]: "time should be smaller  than next in time",
          }));
        } else {
          // setInputErrors((prevErrors) => ({
          //   ...prevErrors,
          //   [`outTime${data22}`]: "",
          // }));
          setAnotherInputErrors((prevErrors) => ({
            ...prevErrors,
            [`outTime${data22}`]: "",
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalData, timingData]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    getInOutList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActivities, startDate, endDate]);

  const handleEdit = async (userId, Date) => {
    ApiUtils.getTimeList(
      `userId=${userId}&date=${Date}&PageSize=${TEN}&Page=1`
    ).then((res) => {
      if (res.data.isSuccess) {
        setTimingData(res?.data?.data?.listResponse);
        setShowModal(true);
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  };

  const handleSubmit = () => {
    const allEmpty = Object.values(inputErrors).every((value) => value === "");
    if (!allEmpty) {
      Toaster("Please Insert Valid Times", WARNING_TOASTIFY_TYPE);
    } else {
      ApiUtils.updateTimes(finalData).then((res) => {
        if (res.data.isSuccess) {
          Toaster("time updated", SUCCESS_TOASTIFY_TYPE);
          setFinalData({});
          setShowModal(false);
          getInOutList();
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">TIMINGS</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            // onChange={handleSearch}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Total Hours
              </th>
              <th scope="col" className="px-6 py-3">
                Missed PunchOut
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">
                      {moment(data.date).format("DD-MM-YYYY")}
                    </td>
                    <td className="px-6 py-4">{data?.totalTime}</td>
                    <td
                      className={`px-6 py-4 ${
                        data.isMissPunch === true && data.outTime === null
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {data?.isMissPunch ? "Yes" : "No"}
                    </td>

                    <td className="px-6 py-4">
                      {" "}
                      <TooltipItem
                        onClick={() => handleEdit(data.userId, data.date)}
                        className="text-lg text-green-500"
                        tooltipsText="Edit"
                      >
                        <FaEdit />
                      </TooltipItem>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalCount > 10 && (
          <div className="mt-3 flex items-center justify-end">
            <nav aria-label="Page navigation example">
              <ul className="inline-flex -space-x-px text-sm">
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center ${
                      currentPage === 1 ? "cursor-no-drop" : ""
                    } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                  >
                    Previous
                  </button>
                </li>

                {Array.from({ length: totalRecords })?.map((_, index) => (
                  <li key={index}>
                    <button
                      onClick={() => handlePageChange(index + 1)}
                      className={`flex items-center justify-center px-3 h-8 ${
                        currentPage === index + 1
                          ? "text-blue-600 border bg-blue-50"
                          : "text-gray-500 border bg-white"
                      } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalRecords}
                    className={`flex items-center justify-center ${
                      currentPage === totalRecords ? "cursor-no-drop" : ""
                    } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
        
         {/* Modal after click on edit button  */}
        {showModal && (
          <div className="fixed inset-0 flex mt-20 items-center justify-center ml-20 bg-gray-800 bg-opacity-80">
            <div className="bg-white w-full rounded-lg shadow-lg p-6 max-w-2xl h-auto  overflow-y-auto">
              <h2 className="text-2xl font-semibold mb-5 ">Edit Time</h2>

              <form>
                {timingData?.map((data, index) => {
                  return (
                    <div key={index} className="flex gap-16 relative">
                      <div className="mb-3 flex">
                        <BsArrowDownLeft className="mt-5 mr-3 text-xl text-green-500" />
                        <input
                          type="time"
                          id={`inTime${index}`}
                          name="inTime"
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          defaultValue={moment(data?.inTime)
                            ?.tz("Asia/Kolkata")
                            ?.format("HH:mm")}
                          onChange={(e) =>
                            handleChange(
                              data?.employeeActivityId,
                              "Intime",
                              e.target.value,
                              data?.activityDate,
                              data?.userId
                            )
                          }
                        />

                        {(anotherInputErrors[
                          `inTime${data?.employeeActivityId}`
                        ] ||
                          inputErrors[`inTime${data?.employeeActivityId}`]) && (
                          <div className="text-xs text-red-600 ml-4 mt-[-4] absolute bottom-0">
                            {anotherInputErrors[
                              `inTime${data?.employeeActivityId}`
                            ] ||
                              inputErrors[`inTime${data?.employeeActivityId}`]}
                          </div>
                        )}
                      </div>
                      {(data?.isMissPunch || data?.outTime !== null) && (
                        <div className="mb-3 flex">
                          <BsArrowUpRight className="mt-5 mr-3 text-xl text-red-500" />
                          <input
                            type="time"
                            id={`outTime${index}`}
                            name="outTime"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            defaultValue={
                              data.outTime !== null
                                ? moment(data?.outTime)
                                    ?.tz("Asia/Kolkata")
                                    ?.format("HH:mm")
                                : "00:00"
                            }
                            onChange={(e) =>
                              handleChange(
                                data?.employeeActivityId,
                                "OutTime",
                                e.target.value,
                                data?.activityDate,
                                data?.userId
                              )
                            }
                          />
                          {(anotherInputErrors[
                            `outTime${data?.employeeActivityId}`
                          ] ||
                            inputErrors[
                              `outTime${data?.employeeActivityId}`
                            ]) && (
                            <div className="text-xs text-red-600 mt-1 absolute bottom-0">
                              {anotherInputErrors[
                                `outTime${data?.employeeActivityId}`
                              ] ||
                                inputErrors[
                                  `outTime${data?.employeeActivityId}`
                                ]}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}

                <div>
                  <label
                    htmlFor="message"
                    className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Note
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write your note here..."
                  />
                </div>
              </form>

              <div className="flex justify-end mt-5">
                <button
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={() => {
                    setShowModal(false);
                    setInputErrors({});
                    setAnotherInputErrors({});
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InOut;
