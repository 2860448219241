import React from "react";
import AppRoutes from "./routes/routes";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <React.Fragment>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        transition={Bounce}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </React.Fragment>
  );
}

export default App;
