import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

const PaySlip = () => {
  const [payslips, setPayslips] = useState([
    {
      id: 1,
      month: "January",
      year: 2024,
      downloadLink: "/download/payslip1.pdf",
    },
    {
      id: 2,
      month: "February",
      year: 2024,
      downloadLink: "/download/payslip2.pdf",
    },
    {
      id: 3,
      month: "March",
      year: 2024,
      downloadLink: "/download/payslip3.pdf",
    },
    {
      id: 4,
      month: "April",
      year: 2024,
      downloadLink: "/download/payslip4.pdf",
    },
    {
      id: 5,
      month: "May",
      year: 2024,
      downloadLink: "/download/payslip5.pdf",
    },
    {
      id: 6,
      month: "June",
      year: 2024,
      downloadLink: "/download/payslip6.pdf",
    },
  ]);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  console.log("🚀 ~ PaySlip ~ setSelectedMonth:", setSelectedMonth)

  // Function to filter payslips by year and month
  const filteredPayslips = payslips.filter((payslip) => {
    if (selectedYear && selectedMonth) {
      return payslip.year === selectedYear && payslip.month === selectedMonth;
    } else if (selectedYear) {
      return payslip.year === selectedYear;
    } else if (selectedMonth) {
      return payslip.month === selectedMonth;
    } else {
      return true;
    }
  });

  return (
    <React.Fragment>
      <div className="flex items-center justify-end pb-1">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">PAYSLIP</h1>
        </div>

        <div className="flex items-center w-1/2 justify-end">
          <div className="mr-2">Filter by Year:</div>
          <div>
            <select
              id="yearFilter"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="border rounded px-2 py-1 text-black"
            >
              <option value="">All</option>
              {Array.from(new Set(payslips?.map((payslip) => payslip.year)))?.map(
                (year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {filteredPayslips?.map((payslip) => (
          <div key={payslip.id} className="border rounded-lg p-4 shadow-md bg-white">
            <div className="font-semibold text-lg mb-2">{payslip.month} {payslip.year}</div>
            <div className="flex justify-between">
        
               <button 
                className="bg-gray-700 text-white px-3 py-1 rounded"
                onClick={() => window.open(payslip.downloadLink)}
              >
                Download
              </button>
              <div className="space-x-2">
                <button className="text-blue-500 hover:text-blue-600 transition duration-300 ease-in-out"><FaEdit /></button>
                <button className="text-red-500 hover:text-red-600 transition duration-300 ease-in-out"><AiFillDelete /></button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default PaySlip;
