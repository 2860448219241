const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  userActivities: {},
};

const inoutSlice = createSlice({
  name: "inout",
  initialState,
  reducers: {
    setUserActivities: (state, action) => {
      state.userActivities = action.payload;
    },
  },
});

export const { setUserActivities } = inoutSlice.actions;
export default inoutSlice.reducer;
