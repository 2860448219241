import React, { useEffect, useState } from "react";
import Select from "react-select";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { HiOutlineDocumentText } from "react-icons/hi";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ONE, TEN } from "../../config/constants";

const SetInterview = () => {
  const [userList, setUserList] = useState([]);
  const [candidateDetails, setCandidateDetails] = useState();
  const [interviewList, setInterviewList] = useState();

  const candidate = useParams();
  const id = candidate.id;

  async function getCandidateDetails() {
    ApiUtils.getCandidateById(id)
      .then((res) => {
        setCandidateDetails(res.data.data.candidateDetails);
        setInterviewList(res.data.data);
      })
      .catch((err) => {
        return err;
      });
  }

  async function getUsers() {
    ApiUtils.getAllUser(`PageSize=${TEN}&Page=${ONE}`).then((res) => {
      if (res.data.isSuccess) {
        setUserList(
          res?.data?.data?.userResponses ? res?.data?.data?.userResponses : []
        );
      } else {
        Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
      }
    });
  }

  const formic = useFormik({
    initialValues: {
      interviewerName: [],
      link: "",
      round: "",
      dateandtimeofinterview: "",
    },
    validationSchema: Yup.object().shape({
      interviewerName: Yup.array().min(1, "Select at least one interviewer"),
      link: Yup.string().required("Please Insert Google Meet Link"),
      round: Yup.string()
        .oneOf(["Technical Round", "CEO Round", "Practical Round"])
        .required("Please Select Round"),
      dateandtimeofinterview: Yup.string().required(
        "Please Select Date Of Interview"
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      const data = {
        candidateId: id,
        interviewer: values.interviewerName?.map((name) => ({
          userId: name.value,
          email: name.email,
        })),
        interviewDate: values.dateandtimeofinterview,
        meetLink: values.link,
        interviewRound: values.round,
      };
      ApiUtils.addInterview(data).then((res) => {
        if (res.data.isSuccess) {
          Toaster("Interview Scheduled", SUCCESS_TOASTIFY_TYPE);
          resetForm({
            values: {
              interviewerName: [],
              link: "",
              round: "",
              dateandtimeofinterview: "",
            },
          });
          getCandidateDetails();
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    },
  });

  useEffect(() => {
    getUsers();
    getCandidateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userData = userList?.map((data) => {
    const users = {
      value: data.userId,
      label: `${data.firstName} ${data.lastName}`,
      email: data.email,
    };
    return users;
  });

  const handleDownloadResume = () => {
    window.open(candidateDetails.resumeLink);
  };

  const exper = candidateDetails?.experience;
  const year = exper?.split(".")[0];
  const month = exper?.split(".")[1];
  return (
    <React.Fragment>
      {interviewList?.interviewDetails?.map((data, index) => {
        // Extract interviewer names based on IDs
        const interviewerIds = data.interviewerId.split(";");
        const initialSelectedOptions = interviewerIds?.map((id) => {
          const userDataEntry = userData.find((entry) => entry.value === id);
          if (userDataEntry) {
            return userDataEntry;
          }
          return { value: id, label: id }; // Fallback to ID if name not found
        });

        return (
          <div
            key={index}
            className="max-w-full mt-5 rounded-2xl bg-white  overflow-hidden shadow-lg"
          >
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">Candidate Details</div>

              <div className="flex space-x-4 mt-4">
                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    disabled
                    className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                    value={candidateDetails?.candidateName}
                  />
                </div>

                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                    value={candidateDetails?.email}
                  />
                </div>

                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="phone"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                    value={candidateDetails?.mobileNo}
                  />
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="name"
                  >
                    Vacancy
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    disabled
                    className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                    value={candidateDetails?.vacancy}
                  />
                </div>

                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="email"
                  >
                    Experience
                  </label>
                  <div className="flex mb-4 items-center">
                    <input
                      name="notice_period_years"
                      type="number"
                      min="0"
                      disabled
                      className="bg-gray-50 cursor-no-drop border w-1/2 mr-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={year}
                    />
                    <input
                      name="notice_period_months"
                      type="number"
                      min="0"
                      max="11"
                      disabled
                      className="bg-gray-50 border cursor-no-drop w-1/2 ml-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={month}
                    />
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 ">
                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="name"
                  >
                    Resume
                  </label>
                  <div
                    onClick={handleDownloadResume}
                    className="flex flex-col items-center justify-center w-60 h-28 border-2 border-gray-300 border-solid rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <HiOutlineDocumentText className="text-6xl" />
                    <p className="mb-2 flex gap-2 text-sm text-gray-500 dark:text-gray-400">
                      <span>{candidateDetails?.resume}</span>
                      <RiDownloadCloud2Line className="mt-1" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="assignInterview"
                  >
                    Interviewer Name
                  </label>
                  <Select
                    name="interviewerName"
                    options={userData}
                    value={initialSelectedOptions}
                    onChange={(selectedOptions) => {
                      formic.setFieldValue("interviewerName", selectedOptions);
                    }}
                    onBlur={formic.handleBlur("interviewerName")}
                    isMulti
                  />

                  {formic.errors.interviewerName &&
                  formic.touched.interviewerName ? (
                    <div className="text-red-500">
                      {formic.errors.interviewerName}
                    </div>
                  ) : null}
                </div>

                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="email"
                  >
                    Date and Time
                  </label>
                  <div className="flex items-center">
                    <input
                      name="dateandtimeofinterview"
                      type="datetime-local"
                      min={new Date().toISOString().slice(0, 16)}
                      onChange={formic.handleChange}
                      onBlur={formic.handleBlur}
                      value={data.interviewDate}
                      className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  {formic.errors.dateandtimeofinterview &&
                  formic.touched.dateandtimeofinterview ? (
                    <div className="text-red-500">
                      {formic.errors.dateandtimeofinterview}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                <div className="flex-grow">
                  <label
                    className="block text-gray-700 text-sm font-semibold mb-1"
                    htmlFor="name"
                  >
                    Link
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="link"
                    value={data?.meetLink}
                    onChange={formic.handleChange}
                    onBlur={formic.handleBlur}
                    className="w-full bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                  />
                  {formic.errors.link && formic.touched.link ? (
                    <div className="text-red-500">{formic.errors.link}</div>
                  ) : null}
                </div>
                <div className="flex-grow">
                  <>
                    <label
                      className="block text-gray-700 text-sm font-semibold mb-1"
                      htmlFor="name"
                    >
                      Select Round
                    </label>
                    <select
                      id="name"
                      name="round"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={formic.handleChange}
                      onBlur={formic.handleBlur}
                      value={data?.interviewRound}
                    >
                      <option selected={true} disabled>
                        Choose Round
                      </option>
                      <option value="Technical Round">Technical Round</option>
                      <option value="CEO Round">CEO Round</option>
                      <option value="Practical Round">Practical Round</option>
                    </select>
                    {formic.errors.round && formic.touched.round ? (
                      <div className="text-red-500">{formic.errors.round}</div>
                    ) : null}
                  </>
                </div>
              </div>
              <div className="flex space-x-4 mt-4">
                <div className="flex-grow">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-center text-gray-700">
                      <thead className="text-xs text-white uppercase bg-gray-700 ">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Interviewer Feedback
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Interviewer Comment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          <td className="px-6 py-4">{data.roundStatus}</td>
                          <td className="px-6 py-4">{data.roundFeadback}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="px-6 pt-4 flex pb-2 justify-between">
              <span className="inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Status: {candidateDetails?.currentStatus}
              </span>

              <div type="button" className="flex justify-end">
                <button className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded-lg">
                  Cancel Interview?
                </button>
                <button className="px-4 py-2 bg-green-400 rounded-lg text-white">
                  Reschedule Interview?
                </button>
              </div>
            </div>
          </div>
        );
      })}
      <div className="max-w-full mt-5 rounded-2xl bg-white  overflow-hidden shadow-lg">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">Candidate Details</div>

          <div className="flex space-x-4 mt-4">
            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                disabled
                className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={candidateDetails?.candidateName}
              />
            </div>

            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={candidateDetails?.email}
              />
            </div>

            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="phone"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={candidateDetails?.mobileNo}
              />
            </div>
          </div>
          <div className="flex space-x-4 mt-4">
            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="name"
              >
                Vacancy
              </label>
              <input
                type="text"
                id="name"
                name="name"
                disabled
                className="w-full cursor-no-drop bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
                value={candidateDetails?.vacancy}
              />
            </div>

            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="email"
              >
                Experience
              </label>
              <div className="flex mb-4 items-center">
                <input
                  name="notice_period_years"
                  type="number"
                  min="0"
                  disabled
                  className="bg-gray-50 cursor-no-drop border w-1/2 mr-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={year}
                />
                <input
                  name="notice_period_months"
                  type="number"
                  min="0"
                  max="11"
                  disabled
                  className="bg-gray-50 border cursor-no-drop w-1/2 ml-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={month}
                />
              </div>
            </div>
          </div>
          <div className="flex space-x-4 ">
            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="name"
              >
                Resume
              </label>
              <div
                onClick={handleDownloadResume}
                className="flex flex-col items-center justify-center w-60 h-28 border-2 border-gray-300 border-solid rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                <HiOutlineDocumentText className="text-6xl" />
                <p className="mb-2 flex gap-2 text-sm text-gray-500 dark:text-gray-400">
                  <span>{candidateDetails?.resume}</span>
                  <RiDownloadCloud2Line className="mt-1" />
                </p>
              </div>
            </div>
          </div>
          <div className="flex space-x-4 mt-4">
            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="assignInterview"
              >
                Interviewer Name
              </label>
              <Select
                name="interviewerName"
                options={userData}
                value={formic.values.interviewerName}
                onChange={(selectedOptions) => {
                  formic.setFieldValue("interviewerName", selectedOptions);
                }}
                onBlur={formic.handleBlur("interviewerName")}
                isMulti
              />
              {formic.errors.interviewerName &&
              formic.touched.interviewerName ? (
                <div className="text-red-500">
                  {formic.errors.interviewerName}
                </div>
              ) : null}
            </div>

            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="email"
              >
                Date and Time
              </label>
              <div className="flex items-center">
                <input
                  name="dateandtimeofinterview"
                  type="datetime-local"
                  min={new Date().toISOString().slice(0, 16)}
                  value={formic.values.dateandtimeofinterview}
                  onChange={formic.handleChange}
                  onBlur={formic.handleBlur}
                  className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              {formic.errors.dateandtimeofinterview &&
              formic.touched.dateandtimeofinterview ? (
                <div className="text-red-500">
                  {formic.errors.dateandtimeofinterview}
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex space-x-4 mt-4">
            <div className="flex-grow">
              <label
                className="block text-gray-700 text-sm font-semibold mb-1"
                htmlFor="name"
              >
                Link
              </label>
              <input
                type="text"
                id="name"
                name="link"
                onChange={formic.handleChange}
                onBlur={formic.handleBlur}
                value={formic.values.link}
                className="w-full bg-gray-100 border border-gray-300 rounded-lg px-3 py-2 text-sm"
              />
              {formic.errors.link && formic.touched.link ? (
                <div className="text-red-500">{formic.errors.link}</div>
              ) : null}
            </div>
            <div className="flex-grow">
              <>
                <label
                  className="block text-gray-700 text-sm font-semibold mb-1"
                  htmlFor="name"
                >
                  Select Round
                </label>
                <select
                  id="name"
                  name="round"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={formic.handleChange}
                  onBlur={formic.handleBlur}
                >
                  <option selected={true} disabled>
                    Choose Round
                  </option>
                  <option value="Technical Round">Technical Round</option>
                  <option value="CEO Round">CEO Round</option>
                  <option value="Practical Round">Practical Round</option>
                </select>
                {formic.errors.round && formic.touched.round ? (
                  <div className="text-red-500">{formic.errors.round}</div>
                ) : null}
              </>
            </div>
          </div>
        </div>

        <hr />
        <div className="px-6 pt-4 flex pb-2 justify-end">
          {/* <span className="inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            Status: {candidateDetails?.currentStatus}
          </span>
          <span className="inline-block  rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            Rescheduled: No
          </span> */}
          <div
            onClick={formic.handleSubmit}
            type="button"
            className="flex justify-end"
          >
            <button className="px-4 py-2 bg-green-400 rounded-lg text-white">
              Schedule Interview
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SetInterview;
