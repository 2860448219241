import React, { useEffect, useState } from "react";
import TooltipItem from "../../helper/TooltipItem";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { ONE } from "../../config/constants";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";
const CreatePaySlip = () => {
  const [showMdal, setShowModal] = useState(false);
  const [userList, setUserList] = useState();
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);
  const toggleModal = () => {
    setShowModal(true);
    userListing();
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  useEffect(() => {
    setTotalCount(2);
    setTotalRecords(1);
  }, []);
  const handleClosePopup = () => {
    setShowModal(false);
  };

  const userListing = () => {
    ApiUtils.getAllUser(`Page=1&Pagesize=500&`).then((res) => {
      setUserList(res?.data?.data?.userResponses);
    });
  };

  const handleEmployeeChange = (e) => {
    console.log(e.target.value);
  };
  const isActive = true;
  return (
    <>
      <div className="container flex items-center mt-3 justify-between mb-2">
        <h1 className="text-lg font-bold">PAYSLIPS</h1>
        <div>
          <button
            type="button"
            className="text-white bg-green-600 w-36 hover:bg-green-900 mb-1 h-auto rounded-full font-bold"
            onClick={toggleModal}
          >
            CREATE PAYSLIP
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Employee Name
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Salary Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 uppercase">keyur trivedi</td>
              <td className="px-6 py-4">React JS</td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded-full ${
                      isActive === true
                        ? "bg-green-500 mr-2"
                        : "bg-red-500 mr-2"
                    } `}
                  ></div>
                  {isActive === true ? "Active" : "Inactive"}
                </div>
              </td>
              <td className="px-6 py-4 flex gap-5">
                <TooltipItem
                  className="text-lg text-green-500"
                  tooltipsText="Edit"
                >
                  <FaEdit />
                </TooltipItem>
                <TooltipItem
                  className="text-lg text-red-500"
                  tooltipsText="Delete"
                >
                  <AiFillDelete />
                </TooltipItem>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white1`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {showMdal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-80 mt-12">
          <div className="bg-white w-full rounded-lg justify-center align-middle mt-12 shadow-lg h-[80vh] p-6 mt-5 max-w-xl overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-5 ">Create Payslip</h2>

            <form>
              <div className="grid gap-6 mb-6">
                <div>
                  <label
                    htmlFor="department"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Select Employee
                  </label>
                  <select
                    id="employee"
                    name="employee"
                    onChange={handleEmployeeChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option selected disabled value="">
                      Select a Employee
                    </option>
                    {userList?.map((user) => {
                      return (
                        <option key={user?.userId} value={user?.userId}>
                          {`${user?.firstName} ${user?.lastName}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="border border-gray-300 mb-6 p-4">
                <h1 className="text-center font-bold text-lg">PAYMENTS</h1>
                <div className="grid mb-6 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="basic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Basic+DA
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="hra"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      HRA
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="grid mb-6 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="basic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Bonus
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="hra"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Other Allowances
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="grid mb-6 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="basic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Arrears
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="hra"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Gross Salary
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
              </div>
              <div className="border border-gray-300 mb-6 p-4">
                <h1 className="text-center font-bold text-lg">DEDUCTIONS</h1>
                <div className="grid mb-6 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="basic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Professional Tax
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="hra"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      TDS
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="grid mb-6 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="basic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Other Deductions
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="hra"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Total Deduction
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="grid mb-6 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="basic"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Net Salary:
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePaySlip;
