import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiUtils from "../../api/ApiUtils";
import Toaster from "../../helper/Toaster";

const BankDetails = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [bankData, setBankData] = useState();

  const getBankData = () => {
    ApiUtils.getBankDetails(id).then((res) => {
      if (res?.data?.isSuccess) {
        setBankData(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getBankData();
  }, []);

  const initialValues = {
    accountnumber: bankData?.accountNo ? bankData?.accountNo : "",
    ifsc: bankData?.ifscCode ? bankData?.ifscCode : "",
    bankName: bankData?.bankName ? bankData?.bankName : "",
    accountHolder: bankData?.accountHolderName
      ? bankData?.accountHolderName
      : "",
    mobile: bankData?.mobileNo ? bankData?.mobileNo : "",
  };

  const validationSchema = Yup.object().shape({
    accountnumber: Yup.string()
      .required("Account number is required")
      .matches(/^\d+$/, "Account number must be a number"),
    ifsc: Yup.string().required("IFSC code is required"),
    bankName: Yup.string().required("Bank name is required"),
    accountHolder: Yup.string()
      .required("Account holder name is required")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Account holder name must contain only letters and spaces"
      ),
    mobile: Yup.string().required("mobile no. is required"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    const addData = {
      accountNo: values.accountnumber,
      ifscCode: values.ifsc,
      bankName: values.bankName,
      accountHolderName: values.accountHolder,
      mobileNo: values.mobile,
      userId: id,
    };
    const updateData = {
      id: bankData?.id,
      accountNo: values.accountnumber,
      ifscCode: values.ifsc,
      bankName: values.bankName,
      accountHolderName: values.accountHolder,
      mobileNo: values.mobile,
      userId: id,
    };
    performOperation(bankData?.id, bankData?.id ? updateData : addData)
      .then((res) => {
        if (res?.status === 200) {
          setIsEditing(false);
          setSubmitting(false);
          getBankData();
        }
      })
      .catch((error) => {
        Toaster(error, "error");
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  const performOperation = (checkMode, params) =>
    checkMode
      ? ApiUtils.updateBankDetails(params)
      : ApiUtils.createBankDetails(params);

  return (
    <div className="bg-white p-4 rounded-lg h-full shadow-md">
      <form>
        <div className="grid grid-cols-3 mb-2 gap-4">
          <div>
            <label
              htmlFor="accountnumber"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              A/C No.:
            </label>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="accountnumber"
              name="accountnumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.accountnumber}
              disabled={!isEditing}
            />
            {formik.touched.accountnumber && formik.errors.accountnumber ? (
              <div className="text-red-500 text-sm">
                {formik.errors.accountnumber}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="ifsc"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              IFSC:
            </label>
            <input
              type="text"
              id="ifsc"
              name="ifsc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.ifsc}
              className="bg-gray-50 border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.ifsc && formik.errors.ifsc ? (
              <div className="text-red-500 text-sm">{formik.errors.ifsc}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="bankName"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Bank Name:
            </label>
            <input
              type="text"
              id="bankName"
              name="bankName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.bankName}
              className="bg-gray-50 border border-gray-300 text-gray-900 uppercase text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.bankName && formik.errors.bankName ? (
              <div className="text-red-500 text-sm">
                {formik.errors.bankName}
              </div>
            ) : null}
          </div>
        </div>
        <div className="grid grid-cols-3 mb-2 gap-4">
          <div>
            <label
              htmlFor="accountHolder"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Account Holder Name:
            </label>
            <input
              type="text"
              id="accountHolder"
              name="accountHolder"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.accountHolder}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.accountHolder && formik.errors.accountHolder ? (
              <div className="text-red-500 text-sm">
                {formik.errors.accountHolder}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Mobile No.:
            </label>
            <input
              type="text"
              id="startDate"
              name="mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.mobile}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
            ) : null}
          </div>
        </div>
      </form>
      <div className="flex justify-end mt-4">
        {!isEditing ? (
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleEdit}
          >
            EDIT
          </button>
        ) : (
          <>
            <button
              type="button"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleSave}
            >
              SAVE
            </button>
            <button
              type="button"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setIsEditing(false)}
            >
              CANCEL
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default BankDetails;
