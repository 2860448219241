import React, { useCallback, useEffect, useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import TooltipItem from "../../helper/TooltipItem";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import ApiUtils from "../../api/ApiUtils";
import moment from "moment/moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import Toaster from "../../helper/Toaster";
import { ERROR_TOASTIFY_TYPE, SUCCESS_TOASTIFY_TYPE } from "../../helper/enum";
import DatePicker from "react-datepicker";
import { debounce } from "../../helper/Debounce";
import { ONE, TEN } from "../../config/constants";

const InterviewSchedule = () => {
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(ONE);
  const [list, setList] = useState();
  const [resume, setResume] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [value, setValue] = useState({
    name: "",
    number: "",
    email: "",
    address: "",
    vacancy: "",
    resume: "",
    currentctc: "",
    expectedctc: "",
    experience_years: "",
    experience_months: "",
    noticeperiod: "",
    source: "",
    comment: "",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const formatedStartDate =
    startDate !== "" ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatedEndDate =
    endDate !== "" ? moment(endDate).format("YYYY-MM-DD") : "";

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowModal((prevState) => !prevState);
    setValue({
      name: "",
      number: "",
      email: "",
      address: "",
      vacancy: "",
      resume: "",
      currentctc: "",
      expectedctc: "",
      experience_years: "",
      experience_months: "",
      noticeperiod: "",
      source: "",
      comment: "",
    });
    formik.resetForm();
    setIsEdit(false);
    setEditId("");
  };

  const handleView = (data) => {
    navigate(`/recruitment/${data}`);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Candidate Name is required"),
    number: Yup.string()
      .required("Mobile Number is required")
      .matches(/^\d{10}$/, "Mobile Number must be 10 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    address: Yup.string().required("Address is required"),
    vacancy: Yup.string().required("Vacancy is required"),
    currentctc: Yup.number().required("Current CTC is required"),
    expectedctc: Yup.number().required("Expected CTC is required"),
    experience_years: Yup.number().required("Years Experience is required"),
    experience_months: Yup.number().required("Months Experience is required"),
    noticeperiod: Yup.string().required("Notice Period is required"),
    source: Yup.string().required("Source is required"),
  });

  const formik = useFormik({
    initialValues: value,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      if (isEdit) {
        formData.append("candidateId", editId);
      }
      formData.append("CandidateName", values.name);
      formData.append("Email", values.email);
      formData.append("MobileNo", values.number);
      formData.append("Address", values.address);
      formData.append("Vacancy", values.vacancy);
      formData.append("Comment", values.comment);
      if (resume) {
        formData.append("Resume", resume);
      }
      formData.append("CurrentCTC", values.currentctc);
      formData.append("ExpectedCTC", values.expectedctc);
      formData.append(
        "Experience",
        `${values.experience_years}.${values.experience_months}`
      );
      formData.append("NoticePeriod", values.noticeperiod);
      formData.append("Source", values.source);

      ApiUtils.addCandidate(formData).then((res) => {
        if (res.data.isSuccess) {
          Toaster(res.data.message, SUCCESS_TOASTIFY_TYPE);
          setValue({
            name: "",
            number: "",
            email: "",
            address: "",
            vacancy: "",
            resume: "",
            currentctc: "",
            expectedctc: "",
            experience_years: "",
            experience_months: "",
            noticeperiod: "",
            source: "",
            comment: "",
          });
          formik.resetForm();
          setShowModal(false);
          candidateList();
        } else {
          Toaster(res.data.message, ERROR_TOASTIFY_TYPE);
        }
      });
    },
  });

  const handleEdit = (data) => {
    const exp = data.experience;
    const expSplit = exp?.split(".");
    const year = expSplit[0];
    const month = expSplit[1];
    setIsEdit(true);
    setEditId(data.candidateId);
    setShowModal(true);
    formik.setValues({
      ...formik.values,
      name: data.candidateName,
      number: data.mobileNo,
      email: data.email,
      address: data.address,
      vacancy: data.vacancy,
      currentctc: data.currentCTC,
      expectedctc: data.expectedCTC,
      experience_years: year,
      experience_months: month,
      noticeperiod: data.noticePeriod,
      source: data.source,
      comment: data.comment,
    });
  };

  async function candidateList() {
    ApiUtils.getCandidateList(
      `Page=${currentPage}&PageSize=${TEN}&SearchValue=${searchValue}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
    ).then((res) => {
      if (res.data.isSuccess) {
        setList(
          res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
        );
        setTotalCount(
          res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
        );
        setTotalRecords(
          res?.data?.data?.totalCount
            ? Math.ceil(res?.data?.data?.totalCount / TEN)
            : []
        );
      }
    });
  }

  const checkEmailExistence = async (email) => {
    try {
      const response = await ApiUtils.candidateEmailValidate(email);
      if (!response.data.isSuccess) {
        formik.setFieldError("email", "Email already exists.");
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };

  const handleResume = (e) => {
    setResume(e.target.files[0]);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    candidateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, startDate, endDate]);

  const handleSearch = async (value) => {
    setSearchValue(value);
    if ((list.length > 0 && value.length >= 3) || value.length === 0) {
      ApiUtils.getCandidateList(
        `Page=${currentPage}&PageSize=${TEN}&SearchValue=${value}&fromDate=${formatedStartDate}&toDate=${formatedEndDate}`
      ).then((res) => {
        if (res.data.isSuccess) {
          setList(
            res?.data?.data?.listResponse ? res?.data?.data?.listResponse : []
          );
          setTotalCount(
            res?.data?.data?.totalCount ? res?.data?.data?.totalCount : ""
          );
          setTotalRecords(
            res?.data?.data?.totalCount
              ? Math.ceil(res?.data?.data?.totalCount / 10)
              : []
          );
        }
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(handleSearch), [list]);

  return (
    <React.Fragment>
      <div className="flex items-center justify-end gap-4 mt-3 pb-2">
        <div className="container flex items-center justify-between">
          <h1 className="text-lg font-bold">RECRUITMENT</h1>
        </div>
        <div>
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            type="text"
            id="table-search"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for items"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        <div className="flex">
          <div className="flex">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={startDate}
              onChange={handleStartDateChange}
              placeholderText="Select start date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
          <div className="flex">
            <label className="ml-2 mr-2">To:</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              showIcon
              todayButton="TODAY"
              selected={endDate}
              onChange={handleEndDateChange}
              placeholderText="Select end date"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className="text-white flex items-center gap-1 bg-green-600 p-5 hover:bg-green-900 h-8 rounded-full font-bold"
            onClick={toggleModal}
          >
            <AiOutlinePlus />
            <span className="text-center">ADD </span>
          </button>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs uppercase  bg-gray-700 text-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Vacancy
              </th>
              <th scope="col" className="px-6 py-3">
                Candidate Name
              </th>

              <th scope="col" className="px-6 py-3">
                Date of application
              </th>
              <th scope="col" className="px-6 py-3">
                Current Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 ? (
              list?.map((data, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-6 py-4">{data.vacancy}</td>
                    <td className="px-6 py-4">{data.candidateName}</td>
                    <td className="px-6 py-4">
                      {moment(data.creationDate).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-6 py-4">
                      {data.currentStatus ? data.currentStatus : "-"}
                    </td>
                    <td className="px-6 py-4 gap-5">
                      <div className="flex items-center space-x-2 gap-4">
                        <TooltipItem
                          onClick={() => handleView(data.candidateId)}
                          className="text-blue-500 text-lg"
                          tooltipsText="View"
                        >
                          <FaEye />
                        </TooltipItem>
                        <TooltipItem
                          onClick={() => handleEdit(data)}
                          className="text-lg text-green-500"
                          tooltipsText="Edit"
                        >
                          <FaEdit />
                        </TooltipItem>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="border px-4 py-2 text-center">
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {totalCount > 10 && (
        <div className="mt-3 flex items-center justify-end">
          <nav aria-label="Page navigation example">
            <ul className="inline-flex -space-x-px text-sm">
              <li>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`flex items-center ${
                    currentPage === 1 ? "cursor-no-drop" : ""
                  } justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Previous
                </button>
              </li>

              {Array.from({ length: totalRecords })?.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => handlePageChange(index + 1)}
                    className={`flex items-center justify-center px-3 h-8 ${
                      currentPage === index + 1
                        ? "text-blue-600 border bg-blue-50"
                        : "text-gray-500 border bg-white"
                    } border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-800`}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalRecords}
                  className={`flex items-center justify-center ${
                    currentPage === totalRecords ? "cursor-no-drop" : ""
                  } px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}

      {showModal && (
        <>
          <div className="fixed inset-0 mt-16 ml-44 flex items-center justify-center bg-gray-800 bg-opacity-80">
            <div className="bg-white w-full rounded-lg shadow-lg p-6 max-h-[90%] max-w-2xl overflow-y-auto">
              <h2 className="text-2xl font-semibold mb-5 ">
                {isEdit ? "Update Candidate" : "Add Candidate"}
              </h2>
              <form>
                <div className="flex gap-5">
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Candidate Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      value={formik.values.name}
                      className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        formik.errors.name && formik.touched.name
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter Candidate Name..."
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className="text-red-500 text-sm mb-0">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="number"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Mobile Number
                    </label>
                    <div className="mb-4 items-center">
                      <input
                        name="number"
                        type="number"
                        value={formik.values.number}
                        className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          formik.errors.number && formik.touched.number
                            ? "border-red-500"
                            : ""
                        }`}
                        placeholder="Enter Candidate Mobile Number.."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.number && formik.touched.number ? (
                        <div className="text-red-500 text-sm mb-0">
                          {formik.errors.number}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      value={formik.values.email}
                      className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        formik.errors.email && formik.touched.email
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter Candidate Email..."
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        checkEmailExistence(e.target.value);
                      }}
                    />

                    {formik.errors.email && formik.touched.email ? (
                      <div className="text-red-500 text-sm mb-0">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="address"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address
                    </label>
                    <div className="mb-4 items-center">
                      <input
                        name="address"
                        type="text"
                        value={formik.values.address}
                        className={`bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          formik.errors.address && formik.touched.address
                            ? "border-red-500"
                            : ""
                        }`}
                        placeholder="Enter Candidate Address.."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.address && formik.touched.address && (
                        <div className="text-red-500 text-sm mb-0">
                          {formik.errors.address}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="vacancy"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Vacancy
                    </label>
                    <input
                      type="text"
                      id="vacancy"
                      value={formik.values.vacancy}
                      className={`bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        formik.errors.vacancy && formik.touched.vacancy
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter Vacancy Details..."
                      name="vacancy"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.vacancy && formik.touched.vacancy && (
                      <div className="text-red-500 text-sm mb-0">
                        {formik.errors.vacancy}
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="resume"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Attach Resume
                    </label>
                    <input
                      type="file"
                      id="resume"
                      accept=".pdf"
                      className="w-full"
                      name="resume"
                      onChange={handleResume}
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="currentctc"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Current CTC(in LPA)
                    </label>
                    <input
                      type="number"
                      id="currentctc"
                      value={formik.values.currentctc}
                      className={`bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        formik.errors.currentctc && formik.touched.currentctc
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter Current CTC..."
                      name="currentctc"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.currentctc && formik.touched.currentctc && (
                      <div className="text-red-500 text-sm mb-0">
                        {formik.errors.currentctc}
                      </div>
                    )}
                  </div>

                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="expectedctc"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Expected CTC(in LPA)
                    </label>
                    <div className="mb-4 items-center">
                      <input
                        name="expectedctc"
                        id="expectedctc"
                        value={formik.values.expectedctc}
                        type="number"
                        className={`bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                          formik.errors.expectedctc &&
                          formik.touched.expectedctc
                            ? "border-red-500"
                            : ""
                        }`}
                        placeholder="Enter Expected CTC.."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.expectedctc &&
                        formik.touched.expectedctc && (
                          <div className="text-red-500 text-sm mb-0">
                            {formik.errors.expectedctc}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="experience"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Experience
                    </label>
                    <div className="flex mb-4 items-center">
                      <div className="w-1/2 mr-2">
                        <input
                          name="experience_years"
                          type="number"
                          value={formik.values.experience_years}
                          min="0"
                          className={`bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            formik.errors.experience_years &&
                            formik.touched.experience_years
                              ? "border-red-500"
                              : ""
                          }`}
                          placeholder="Years"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.experience_years &&
                          formik.touched.experience_years && (
                            <div className="text-red-500 text-sm mb-0">
                              {formik.errors.experience_years}
                            </div>
                          )}
                      </div>
                      <div className="w-1/2 ml-2">
                        <input
                          name="experience_months"
                          type="number"
                          min="0"
                          max="11"
                          value={formik.values.experience_months}
                          className={`bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                            formik.errors.experience_months &&
                            formik.touched.experience_months
                              ? "border-red-500"
                              : ""
                          }`}
                          placeholder="Months"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.experience_months &&
                          formik.touched.experience_months && (
                            <div className="text-red-500 text-sm mb-0">
                              {formik.errors.experience_months}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="noticeperiod"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Notice Period
                    </label>
                    <input
                      type="text"
                      id="noticeperiod"
                      value={formik.values.noticeperiod}
                      className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        formik.errors.noticeperiod &&
                        formik.touched.noticeperiod
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter Candidate Email..."
                      name="noticeperiod"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.noticeperiod &&
                    formik.touched.noticeperiod ? (
                      <div className="text-red-500 text-sm mb-0">
                        {formik.errors.noticeperiod}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex gap-5">
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="source"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Source
                    </label>
                    <input
                      type="text"
                      id="source"
                      value={formik.values.source}
                      className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                        formik.errors.source && formik.touched.source
                          ? "border-red-500"
                          : ""
                      }`}
                      placeholder="Enter Resource..."
                      name="source"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.source && formik.touched.source ? (
                      <div className="text-red-500 text-xs mb-0">
                        {formik.errors.source}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-2 flex-grow">
                    <label
                      htmlFor="comment"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Comments(if any)
                    </label>
                    <div className="mb-4 items-center">
                      <textarea
                        name="comment"
                        value={formik.values.comment}
                        className="bg-gray-50 border w-full mr-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Comments"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="flex justify-end">
                <button
                  className="mr-2 px-4 py-2 text-red-600 hover:bg-red-600 hover:text-white border rounded"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  onClick={formik.handleSubmit}
                  type="submit"
                  className="px-4 py-2 bg-green-400 text-white rounded"
                >
                  {isEdit ? "Update" : "Save"}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default InterviewSchedule;
