import React from "react";

const footer = () => {
  return (
    <footer className="bg-white text-center dark:bg-neutral-700 lg:text-left">
      <div className="p-2 text-center justify-center mt-2 text-neutral-700 dark:text-neutral-200">
        © 2024 Copyright{" "}
        <a
          className="text-neutral-800 dark:text-neutral-400"
          href="https://www.tecmantras.com/"
        >
          TecMantras
        </a>
      </div>
    </footer>
  );
};

export default footer;
