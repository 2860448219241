import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiUtils from "../../api/ApiUtils";
import { Toaster } from "react-hot-toast";

const PersonalDetail = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [personalData, setPersonalData] = useState();

  const getPersonalData = () => {
    ApiUtils.getPersonalDetails(id).then((res) => {
      if (res?.data?.isSuccess) {
        setPersonalData(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getPersonalData();
  }, []);

  const initialValues = {
    addhar: personalData?.addharCardNo ? personalData?.addharCardNo : "",
    name: personalData?.addharCardName ? personalData?.addharCardName : "",
    pan: personalData?.panNo ? personalData?.panNo : "",
    fatherName: personalData?.fatherName ? personalData?.fatherName : "",
    motherName: personalData?.motherName ? personalData?.motherName : "",
    contact: personalData?.emergencyContactNo
      ? personalData?.emergencyContactNo
      : "",
    presentAddress: personalData?.presentAddress
      ? personalData?.presentAddress
      : "",
    permanentAddress: personalData?.permnetAddress
      ? personalData?.permnetAddress
      : "",
  };

  const validationSchema = Yup.object().shape({
    addhar: Yup.string()
      .required("Aadhar Card number is required")
      .matches(/^\d{12}$/, "Aadhar Card number must be exactly 12 digits long"),
    name: Yup.string().required("Name is required"),
    pan: Yup.string()
      .required("PAN number is required")
      .matches(/^\w{10}$/, "Invalid PAN number"),
    fatherName: Yup.string().required("Father's name is required"),
    motherName: Yup.string().required("Mother's name is required"),
    contact: Yup.string().required("Emergency contact number is required"),
    presentAddress: Yup.string().required("Present address is required"),
    permanentAddress: Yup.string().required("Permanent address is required"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    const addData = {
      addharCardName: values.name,
      addharCardNo: values.addhar,
      panNo: values.pan,
      fatherName: values.fatherName,
      motherName: values.motherName,
      emergencyContactNo: values.contact,
      presentAddress: values.presentAddress,
      permnetAddress: values.permanentAddress,
      userId: id,
    };

    const updateData = {
      id: personalData?.id,
      addharCardName: values.name,
      addharCardNo: values.addhar,
      panNo: values.pan,
      fatherName: values.fatherName,
      motherName: values.motherName,
      emergencyContactNo: values.contact,
      presentAddress: values.presentAddress,
      permnetAddress: values.permanentAddress,
      userId: id,
    };
    performOperation(personalData?.id, personalData?.id ? updateData : addData)
      .then((res) => {
        if (res?.status === 200) {
          setIsEditing(false);
          setSubmitting(false);
          getPersonalData();
        }
      })
      .catch((error) => {
        Toaster(error, "error");
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  const performOperation = (checkMode, params) =>
    checkMode
      ? ApiUtils.updatePersonalDetails(params)
      : ApiUtils.createPersonalDetails(params);

  return (
    <div className="bg-white p-4 rounded-lg h-full shadow-md">
      <form>
        <div className="grid grid-cols-3 mb-2 gap-4">
          <div>
            <label
              htmlFor="addhar"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Addhar No.:
            </label>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="addhar"
              name="addhar"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.addhar}
              disabled={!isEditing}
            />
            {formik.touched.addhar && formik.errors.addhar ? (
              <div className="text-red-500 text-sm">{formik.errors.addhar}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="name"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Name As Per AddharCard:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.name}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="pan"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              PAN No.:
            </label>
            <input
              type="text"
              id="pan"
              name="pan"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.pan}
              className="bg-gray-50 border border-gray-300 uppercase text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.pan && formik.errors.pan ? (
              <div className="text-red-500 text-sm">{formik.errors.pan}</div>
            ) : null}
          </div>
        </div>
        <div className="grid grid-cols-3 mb-2 gap-4">
          <div>
            <label
              htmlFor="fatherName"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Father Name:
            </label>
            <input
              type="text"
              id="fatherName"
              name="fatherName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.fatherName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.fatherName && formik.errors.fatherName ? (
              <div className="text-red-500 text-sm">
                {formik.errors.fatherName}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="motherName"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Mother Name:
            </label>
            <input
              type="text"
              id="motherName"
              name="motherName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.motherName}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.motherName && formik.errors.motherName ? (
              <div className="text-red-500 text-sm">
                {formik.errors.motherName}
              </div>
            ) : null}
          </div>

          <div>
            <label
              htmlFor="contact"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Emergency Contact No.:
            </label>
            <input
              type="text"
              id="contact"
              name="contact"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.contact}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.contact && formik.errors.contact ? (
              <div className="text-red-500 text-sm">
                {formik.errors.contact}
              </div>
            ) : null}
          </div>
        </div>
        <div className="grid grid-cols-2 mb-2 gap-4">
          <div>
            <label
              htmlFor="presentAddress"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Present Address:
            </label>
            <textarea
              id="presentAddress"
              name="presentAddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.presentAddress}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.presentAddress && formik.errors.presentAddress ? (
              <div className="text-red-500 text-sm">
                {formik.errors.presentAddress}
              </div>
            ) : null}
          </div>
          <div>
            <label
              htmlFor="permanentAddress"
              className="block text-sm mb-2 text-gray-700 font-semibold"
            >
              Permanent Address:
            </label>
            <textarea
              id="permanentAddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue={formik.values.permanentAddress}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              disabled={!isEditing}
            />
            {formik.touched.permanentAddress &&
            formik.errors.permanentAddress ? (
              <div className="text-red-500 text-sm">
                {formik.errors.permanentAddress}
              </div>
            ) : null}
          </div>
        </div>
      </form>
      <div className="flex justify-end mt-4">
        {!isEditing ? (
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={handleEdit}
          >
            EDIT
          </button>
        ) : (
          <>
            <button
              type="button"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleSave}
            >
              SAVE
            </button>
            <button
              type="button"
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setIsEditing(false)}
            >
              CANCEL
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalDetail;
