import axios from "axios";
import { BASE_URL } from "../config/constants";
import store from "../redux/store";
import { removeUserAuthDetails } from "../redux/slices/authSlice";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(
  function (config) {
    const loader = document.querySelector(".loading");
    loader.style.display = "block";

    if (
      config.url === "Candidate/AddUpdateCandidate" ||
      config.url === "HrExpense/AddExpense" ||
      config.url === "HrExpense/UpdateExpense"
    ) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails?.userToken) {
      config.headers["Authorization"] = `Bearer ${userDetails?.userToken}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const loader = document.querySelector(".loading");
    loader.style.display = "none";
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      store.dispatch(removeUserAuthDetails());
      localStorage.removeItem("user");
      const loader = document.querySelector(".loading");
      loader.style.display = "none";
      return Promise.reject(error);
    } else {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const loader = document.querySelector(".loading");
      loader.style.display = "none";
      return Promise.reject(error);
    }
  }
);
export default instance;
