export const SUCCESS_TOASTIFY_TYPE = "success";
export const ERROR_TOASTIFY_TYPE = "error";
export const WARNING_TOASTIFY_TYPE = "warning";
export const INFO_TOASTIFY_TYPE = "info";

//credentials

//ADMIN
// id: niravtest @tecmantras.com
// pass: Admin@123

//EMPLOYEE
// id: mahesh.tecmantras111@gmail.com
// pass: Admin@123

//MANAGER
// id: gourav.tecmantras@gmail.com
// pass: Test@123

//HR
// id: jauzoilleinneubra-1178@yopmail.com
// pass: Test@123
