import React from "react";

const InputField = ({
  label,
  id,
  type,
  placeholder,
  required,
  name,
  value,
  setValue,
  error,
  setError,
  validator,
  validationMessage,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const isValid = validator ? validator(value) : true;
    setValue((prevFormData) => ({ ...prevFormData, [name]: value }));
    setError((prevFormData) => ({
      ...prevFormData,
      [name]: required && !isValid,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (required && !value.trim()) {
      setError((prevFormData) => ({ ...prevFormData, [name]: true }));
    } else {
      setError((prevFormData) => ({ ...prevFormData, [name]: false }));
    }
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {label}
      </label>
      <input
        type={type}
        id={id}
        className={`bg-gray-50 border ${
          error[name] ? "border-red-500" : "border-gray-300"
        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
      />
      {error[name] && (
        <p className="text-red-500 text-sm mt-1 dark:text-red-400">
          {validationMessage
            ? validationMessage
            : required
            ? `Please enter a valid ${label.toLowerCase()}.`
            : ""}
        </p>
      )}
    </div>
  );
};

export default InputField;
